import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  batch,
} from 'react-redux';
import practice from './slices/practice';
import userSlice, { userMiddleware } from './slices/userSlice';
import feedbackSlice from './slices/feedbackSlice';
import coordinateFollow from './slices/coordinateFollow';
import evaluationRecordSlice from './slices/evaluationRecordSlice';
import { topicSlice } from './slices/topicSlice';
import { compositionSlice } from './slices/compositionSlice';
import { readingComprehensionSlice } from './slices/readingComprehensionSlice';
import { listeningSlice } from './slices/listeningSlice';
import { audioPlayerSlice } from './slices/audioPlayerSlice';
import { listeningEnhanceSlice } from './slices/listeningEnhanceSlice';
import { drawingBoardSlice } from './slices/drawingSlice';
import { readingListSlice } from './slices/readingListSlice';
import { listeningListSlice } from './slices/listeningListSlice';
import { questionReviewSlice } from './slices/questionReviewSlice';
import { writingIdeasSlice } from './slices/writingIdeasSlice';
import { topicListSlice2 } from './slices/topicListSlice2';
import { essaySlice } from './slices/essaySlice';
import { evaluationSlice } from './slices/evaluationSlice';
import { historyListSlice } from './slices/historyListSlice';
import { modelEssaySlice } from './slices/modelEssaySlice';
import { searchSlice } from './slices/searchSlice';
import { basicTraingList } from './slices/basicTrainingListSlice';
// const logger = createLogger();

const store = configureStore({
  reducer: {
    historyList: historyListSlice.reducer,
    topic: topicSlice.reducer,
    practice: practice.reducer,
    user: userSlice.reducer,
    feedback: feedbackSlice.reducer,
    compositionSlice: compositionSlice.reducer,
    coordinateFollow: coordinateFollow.reducer,
    evaluationRecord: evaluationRecordSlice.reducer,
    evaluationSlice: evaluationSlice.reducer,
    readingComprehension: readingComprehensionSlice.reducer,
    readingList: readingListSlice.reducer,
    listening: listeningSlice.reducer,
    audioPlayer: audioPlayerSlice.reducer,
    listeningEnhance: listeningEnhanceSlice.reducer,
    listeningList: listeningListSlice.reducer,
    drawingBoard: drawingBoardSlice.reducer,
    questionReviewSlice: questionReviewSlice.reducer,
    writingIdeasSlice: writingIdeasSlice.reducer,
    topicListSlice2: topicListSlice2.reducer,
    essaySlice: essaySlice.reducer,
    modelEssay: modelEssaySlice.reducer,
    searchList: searchSlice.reducer,
    basicTraingList: basicTraingList.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().prepend(userMiddleware.middleware);
  },
});

export default store;
export const appDispatch = store.dispatch;
export type ReduxStateType = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export { batch };
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;

export * from './slices/practice';
export * from './slices/coordinateFollow';
export * from './slices/topicSlice';
export * from './slices/readingComprehensionSlice';
export * from './slices/listeningSlice';
export * from './slices/readingListSlice';
export * from './slices/listeningListSlice';
export * from './slices/evaluationSlice';
export * from './slices/historyListSlice';
export * from './slices/modelEssaySlice';
