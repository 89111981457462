import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from '@/locales/init';
import { EssayPaperData, GetEssayPaperDataResponse, IEssayAvailableData, SaveEssayRequest, SaveEssayResponse, getEssayPaperData, getEssayRecordData, getEssaySubjectAvailable, saveEssayRecordData } from '@/api/writing/essay';
import { ITopicItem } from '@/api/writing/topicList2';

const initialState: {
  id: number; // paper_id
  recordId?: string;
  info?: EssayPaperData;
  essayData?: GetEssayPaperDataResponse;
  feedback?: boolean;
  saveCode?: SaveEssayResponse;
  content: string;
  subject_url?: string;
  subjectState?: IEssayAvailableData;
  isDraft?: boolean; // 自拟题
  submiting?: boolean;
  preItem?: ITopicItem;
} = {
  id: 0,
  content: '',
  subject_url: '',
  isDraft: false,
};

export const getEssayPaperInfo = createAsyncThunk('essay/getEssayPaperInfo', async (
  params: { id: string; }
) => {
  const { id } = params;
  try {
    const info = await getEssayPaperData({
      id: Number(id),
    }); // 
    if (info.data) {
      return info.data;
    }
  } catch (error) {
    return;
  }
  return;
});

export const saveEssayRecordInfo = createAsyncThunk('essay/saveEssayRecordInfo', async (
  params: SaveEssayRequest
) => {
  const info = await saveEssayRecordData(params); // 
  if (info.data) {
    return info.data;
  }
  return;
});

export const getEssayRecordInfo = createAsyncThunk('essay/getEssayRecordInfo', async (
  params: { id: string; }
) => {
  const { id } = params;
  const info = await getEssayRecordData({
    id: Number(id),
  }); // 
  if (info.data) {
    return info.data;
  }
  return;
});

export const checkEssaySubject = createAsyncThunk('essay/checkEssaySubject', async (
  params: { subject_url: string; },
) => {
  const { subject_url } = params;
  try {
    const info = await getEssaySubjectAvailable({ subject_url });
    if (info.data) {
      return info.data;
    }
  } catch (error) {
    console.log(error);
  }
  return;
});



export const essaySlice = createSlice({
  name: 'questionReview',
  initialState,
  reducers: {
    reset: (state) => {
      state.id = 0;
      state.content = '';
      state.info = undefined;
      state.essayData = undefined;
      state.feedback = undefined;
      state.recordId = undefined;
      state.saveCode = undefined;
      state.subject_url = '';
      state.isDraft = false;
      state.submiting = false;
      state.subjectState = undefined;
    },
    setID: (state, action: { payload: { id: number; recordId?: string; }; }) => {
      state.id = action.payload.id;
      state.recordId = action.payload.recordId;
    },

    setDraft: (state) => {
      state.isDraft = true;
    },
    setContent: (state, action: { payload: string; }) => {
      state.content = action.payload;
    },
    setSubject: (state, action: { payload: string; }) => {
      state.subject_url = action.payload;
    },
    setSaveCode: (state, action: { payload: SaveEssayResponse; }) => {
      state.saveCode = action.payload;
    },
    setSubmiting: (state, action: { payload: boolean; }) => {
      state.submiting = action.payload;
    },
    setPreItem: (state, action: { payload: ITopicItem | undefined; }) => {
      state.preItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEssayPaperInfo.fulfilled, (state, action) => {
        state.info = action.payload;
      })
      .addCase(getEssayRecordInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.essayData = action.payload;
          state.feedback = action.payload.status !== 1;
          state.content = action.payload.content;
          state.subject_url = action.payload.subject_url;
        }
      })
      .addCase(checkEssaySubject.pending, (state) => {
        state.subjectState = { is_available: 0, humor_reply: i18n.t('common.dauyan_checking_topic') };
      })
      .addCase(checkEssaySubject.fulfilled, (state, action) => {
        if (action.payload) {
          state.subjectState = action.payload;
        }
      });

  }
});
export const EssayActions = essaySlice.actions;

