export default `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.9062 12.2085H17.0931V12.248H16.9062V12.2085Z" fill="#231F20"/>
<path d="M3 7.3252L11 8.74903L17 12.3086V27.5302H14.926L13 26.547H10.5L9 27.9709L6.5 22.2755L3 7.3252Z" fill="#F9837C"/>
<path d="M31 7.3252L23 8.74903L17 12.3086V27.5302H19.074L21 26.547L22 25.8351L23.5 26.547L25 27.9709L27.5 22.2755L31 7.3252Z" fill="#F9837C"/>
<path d="M14.8212 27.606L14.9285 27.6606C14.6647 27.363 13.9902 26.6691 13.1117 26.2568C12.0667 25.7661 10.5098 25.5856 9.02759 27.5107C9.01073 27.5327 8.98953 27.5401 8.96633 27.5318C8.61188 27.4079 7.07744 22.0091 5.71055 16.9502C5.63631 17.0216 5.56414 17.1024 5.48875 17.1873C5.35603 17.3366 5.21207 17.4975 5.03516 17.6251C6.74359 23.705 8.45769 29.6539 8.70875 29.9994C10.2699 25.287 12.3794 26.3616 14.8212 27.606Z" fill="white"/>
<path d="M25.0369 27.4626C25.0135 27.4709 24.9924 27.4635 24.9756 27.4415C23.4928 25.5157 21.9351 25.6962 20.8897 26.1865C20.0157 26.5966 19.3442 27.2847 19.0781 27.5838L19.088 27.5786C21.3631 26.3896 23.7155 25.16 25.2944 29.9243C25.5383 29.5887 27.1625 23.9662 28.8211 18.072C28.8025 18.0598 28.7845 18.0489 28.768 18.0426C28.4882 17.9353 28.287 17.7381 28.1171 17.5332C26.797 22.3924 25.3762 27.344 25.0369 27.4626Z" fill="white"/>
<path d="M4 4.47705H6.5L11.5 6.61281L17 12.3082V25.3788H15.0741L13.2857 24.3617H10.9643L9.57143 25.8346L7.25 19.9429L5 6.61281L4 4.47705Z" fill="#F9837C"/>
<path d="M30 4.47705H27.5L22.5 6.61281L17 12.3082V25.3788H18.9259L20.7143 24.3617H23.0357L24.4286 25.8346L26.75 19.9429L29 6.61281L30 4.47705Z" fill="#F9837C"/>
<path d="M16.7934 12.2867L16.7851 12.2731C16.1602 11.2436 10.4899 2.2766 3.94172 4.39806C3.89571 4.49956 4.23375 5.5047 4.59139 6.42436L5.72652 6.37869L5.1393 4.90844L5.17878 4.8993C8.99086 4.02212 15.363 10.6943 16.6016 12.0408L16.5712 12.1024C16.6057 12.1305 16.6395 12.1585 16.6724 12.1864L16.7271 12.2327L16.6661 12.2583C16.6457 12.2669 16.624 12.3093 16.624 12.371L16.9462 27.4309C16.9462 27.4999 16.9728 27.5503 16.9967 27.5503H17.0548C17.0786 27.5503 17.1053 27.4999 17.1053 27.4322L17.4274 12.3868C17.4274 12.3293 17.4062 12.2868 17.377 12.2868H17.2473L17.3316 12.2135C18.7594 10.9722 21.6703 9.54666 24.7474 8.58167C27.2177 7.8069 30.0475 7.28244 30.5808 8.13427L30.592 8.15188L30.5863 8.17409C30.5236 8.4181 30.0189 10.3834 29.3354 12.9734C29.6012 13.0685 29.8909 13.1907 30.1461 13.3294C30.8561 10.7778 31.4993 8.44539 31.9535 6.78845C24.9257 5.77489 19.1172 10.5385 17.5021 12.0249L17.4702 11.9603C19.2064 10.0722 25.1553 3.97727 28.8216 4.82103L28.8611 4.83017L28.274 6.29848L29.409 6.34057C29.7666 5.42449 30.1046 4.42297 30.0559 4.31886C23.5315 2.20718 17.8412 11.2365 17.2139 12.2731L17.2056 12.2868H17.1546V12.2473H17.0933H16.9065H16.8456V12.2868H16.7934V12.2867Z" fill="white"/>
<path d="M3.41421 8.23599L3.4085 8.21378L3.41954 8.19612C3.95054 7.3483 6.78269 7.86256 9.25624 8.62649C12.2288 9.54456 15.0515 10.8836 16.5301 12.0699C14.8575 10.5409 9.06893 5.85232 2.04688 6.86501C2.4705 8.41029 3.05864 10.5432 3.71185 12.8934C3.9981 12.788 4.30407 12.7055 4.56328 12.6509C3.93185 10.2527 3.47367 8.46781 3.41421 8.23599Z" fill="white"/>
<path d="M2.85206 13.8437C2.82797 14.0464 2.90823 14.2179 2.95817 14.3021C3.07947 14.5066 3.24399 14.5877 3.29753 14.5397C3.75537 14.1304 4.48598 13.7723 5.4689 13.4756C5.58714 13.4399 5.91777 13.2887 5.88756 13.0689C5.84771 12.7812 5.54018 12.7207 5.2892 12.7207C5.20874 12.7207 5.13888 12.727 5.09712 12.7308C5.08095 12.7322 5.06841 12.7333 5.06029 12.7337C4.68889 12.752 3.6882 12.9861 3.16801 13.314C3.03887 13.3955 2.87629 13.6394 2.85206 13.8437Z" fill="#FFD3D0"/>
<path d="M3.10414 15.3136C3.1653 15.6199 3.278 15.7752 3.43915 15.7752C3.55158 15.7752 3.64828 15.6992 3.67522 15.6759L3.70003 15.6543C3.92404 15.459 4.60582 14.8645 5.65965 14.6909C6.12809 14.6137 6.67624 14.2812 6.65429 13.984C6.64698 13.8836 6.62487 13.5786 6.17256 13.5786C5.93449 13.5786 5.65669 13.6588 5.41641 13.7372C4.84345 13.9248 3.88456 14.3433 3.37031 14.6675C3.25184 14.742 3.04509 15.0167 3.10414 15.3136Z" fill="#FFD3D0"/>
<path d="M5.95222 14.8724C5.94029 14.8745 5.93003 14.8763 5.92181 14.8774C5.44403 14.9409 4.5645 15.2614 3.76274 15.8151C3.63825 15.9012 3.49827 16.1236 3.48933 16.3327C3.47927 16.5708 3.54339 16.7209 3.59897 16.805C3.6829 16.932 3.78799 16.9772 3.85836 16.9772C3.88105 16.9772 3.9013 16.9728 3.91856 16.9641C4.26023 16.7924 4.50405 16.5956 4.73985 16.4052C4.95666 16.2302 5.18087 16.0492 5.47284 15.8974C5.48327 15.892 5.5097 15.8817 5.54776 15.8672C6.31108 15.5768 6.40707 15.3537 6.39637 15.2465C6.36993 14.9821 6.26783 14.8589 6.07518 14.8589C6.02741 14.8589 5.98395 14.8667 5.95222 14.8724Z" fill="#FFD3D0"/>
<path d="M5.42303 16.2013L5.40808 16.2089C5.06189 16.3825 4.85188 16.5733 4.43349 16.9533C4.36108 17.0191 4.28256 17.0904 4.19616 17.1682C4.13615 17.2221 4.10123 17.2809 4.09226 17.3433C4.08359 17.4035 4.0983 17.4734 4.13592 17.5512C4.23312 17.7517 4.30828 17.775 4.35632 17.775H4.35636C4.40637 17.775 4.45758 17.7455 4.50708 17.7171C4.54344 17.6961 4.58101 17.6745 4.6189 17.6641C4.97352 17.5664 5.20072 17.3106 5.42048 17.0632C5.52027 16.951 5.61449 16.8449 5.71809 16.7558C5.72437 16.7504 5.73253 16.7438 5.74221 16.736C5.81441 16.6773 6.00621 16.5215 6.03203 16.3299C6.04236 16.2532 6.02524 16.1763 5.97968 16.095C5.96388 16.0668 5.9247 16.0513 5.86932 16.0513C5.71598 16.0512 5.49544 16.1641 5.42303 16.2013Z" fill="#FFD3D0"/>
<path d="M28.5675 12.9212C28.5003 12.9081 28.3755 12.8838 28.2391 12.8838C28.0469 12.8838 27.808 12.9347 27.7614 13.1771C27.7202 13.3929 28.0423 13.5779 28.1584 13.6256C29.1237 14.0228 29.8342 14.455 30.2702 14.9101C30.2818 14.9223 30.2999 14.9288 30.3222 14.9288C30.4008 14.9288 30.5335 14.8456 30.6349 14.6866C30.7186 14.5556 30.7594 14.4048 30.7497 14.2621C30.7359 14.0556 30.5862 13.7956 30.4615 13.7009C29.9592 13.3203 28.9732 12.9841 28.6036 12.9278C28.5956 12.9266 28.5833 12.9243 28.5675 12.9212Z" fill="#FFD3D0"/>
<path d="M30.1919 15.0309C29.6957 14.655 28.7604 14.139 28.1984 13.8928C27.9256 13.7737 27.6127 13.6519 27.3606 13.6519C27.0337 13.6519 26.9703 13.857 26.951 14.0114C26.9142 14.3057 27.4441 14.6938 27.9073 14.8189C28.9488 15.1001 29.5987 15.7629 29.8123 15.9807L29.8365 16.0053C29.8654 16.0346 29.9692 16.13 30.0914 16.13C30.2399 16.13 30.352 15.9862 30.4245 15.7027C30.4986 15.4128 30.3063 15.1175 30.1919 15.0309Z" fill="#FFD3D0"/>
<path d="M29.7402 16.1327C28.9684 15.4978 28.1074 15.0877 27.6341 14.9754C27.6261 14.9735 27.6161 14.9707 27.6045 14.9673C27.565 14.9561 27.5052 14.939 27.4417 14.939C27.2768 14.939 27.1789 15.0553 27.1423 15.2946C27.1262 15.4005 27.2107 15.633 27.9575 16.0011C27.9946 16.0193 28.0204 16.0323 28.0304 16.0386C28.314 16.2202 28.5284 16.4238 28.7358 16.6206C28.9613 16.8347 29.1945 17.0561 29.5266 17.2626C29.5478 17.2758 29.5747 17.2827 29.6045 17.2827C29.7611 17.2827 29.9831 17.0948 29.9866 16.6771C29.9884 16.4677 29.8601 16.2315 29.7402 16.1327Z" fill="#FFD3D0"/>
<path d="M28.0827 16.3568L28.0681 16.3476C27.8613 16.2167 27.7052 16.1504 27.604 16.1504C27.575 16.1504 27.5365 16.1562 27.5185 16.1841C27.4689 16.2606 27.4479 16.3354 27.4542 16.4129C27.4702 16.6065 27.6536 16.7816 27.7227 16.8475C27.7319 16.8564 27.7398 16.8639 27.7457 16.8699C27.8446 16.9694 27.9332 17.0849 28.027 17.2073C28.2337 17.4766 28.4473 17.7551 28.796 17.8888C28.8333 17.9031 28.8697 17.9286 28.9049 17.9532C28.9569 17.9896 29.0107 18.0271 29.0653 18.0271C29.1358 18.0271 29.2073 17.9614 29.2836 17.826C29.325 17.7522 29.3432 17.684 29.3376 17.6231C29.3318 17.56 29.3 17.4977 29.2429 17.4377C29.161 17.3517 29.0866 17.2728 29.018 17.2C28.6195 16.7776 28.4196 16.5656 28.0827 16.3568Z" fill="#FFD3D0"/>
</svg>
`;