import Axios from 'axios';
import { AxiosResponseType, post } from '@/utils/network/axios-helper';
import type { ITopicInfo } from '@/stores/slices/topicSlice';
/**
 * 获取练习记录
 */
export interface IpracticeInfo {
  id: number;
  record_id?: number;
}

export const getPracticeInfo: (params: IpracticeInfo) => Promise<AxiosResponseType<ITopicInfo>> = async (params) => {
  return await post('/api/exercise/get-paper-info', params);
};

/**
 * 提交练习
 */
export interface ISubmitPracticeParams {
  exercise_id: number; // 题目ID
  record_id: number; // 练习记录ID 0为新增
  content: string; // 内容
  outline: string; // 提纲
}

export interface ISubmitPracticeRes {
  record_id: number;
}

export const submitPractice = async (data: ISubmitPracticeParams): Promise<AxiosResponseType<ISubmitPracticeRes>> => {
  return Axios.post('/api/exercise/generate-score', data);
};

/**
 * 保存练习
 */
export interface ISavePracticeParams {
  exercise_id: number; // 题目ID
  id: number; // 练习记录ID 0 为新增
  content: string; // 内容
  outline: string; // 提纲
}

export interface ISavePracticeRes {
  id: number;
}

export const savePractice = async (data: ISavePracticeParams): Promise<AxiosResponseType<ISavePracticeRes>> => {
  return Axios.post('/api/exercise/save-record', data);
};

export interface ISaveSegmentedParams {
  paper_id: number;
  type: number;
  start: string;
  end: string;
  body: string;
  expand?: string;
  ai_guide?: string;
  ai_corrected_info?: string;
}
export const submitSegmentedInfoApi = async (data: ISaveSegmentedParams): Promise<AxiosResponseType<ISavePracticeRes>> => {
  return Axios.post('/api/exercise/save-segmented-info', data);
};

export interface IGetSegmentedParams {
  paper_id: number;
  type: number;
}
export const getSegmentedInfoApi = async (data: IGetSegmentedParams): Promise<AxiosResponseType<ISavePracticeRes>> => {
  return Axios.post('/api/exercise/get-segmented-info', data);
};

/**
 * 分段批改
 * @param data 
 * @returns 
 */
export const submitSegmentedCorrection = async (data: ISaveSegmentedParams): Promise<AxiosResponseType<ISavePracticeRes>> => {
  return Axios.post('/api/exercise/segmented-submit-correct', data);
};

/**
 * 评分
 * @param data 
 * @returns 
 */
export const submitSegmentedRecord = async (data: ISaveSegmentedParams): Promise<AxiosResponseType<ISavePracticeRes>> => {
  return Axios.post('/api/exercise/segmented-submit-scoring', data);
};


export interface IBigEssayAssociation {
  thought: string;
  essay: string;
  input_text: string;
  paper_id: number;
  type: number;
}
/**
 * 联想
 * @param data 
 * @returns 
 */
export const getBigEssayAssociation = async (data: IBigEssayAssociation): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exercise/big-essay-association', data);
};


export interface getNextPageRequest {
    category?: number;
    content?: string;
    id: number;
    subject_type?: number;
    topic?: number;
    year?: number;
}
export const getNextTopicId = async (data: getNextPageRequest): Promise<AxiosResponseType<{id:number;}>> => {
  return Axios.post('/api/exercise/get-next-page', data);
};
export interface savePracticeStepRequest {
    paper_id: number;
    /**
     * 1审题练习 2思路练习 3分段练习
     */
    step: number;
    type: number;
}
export const savePracticeStep = async (data: savePracticeStepRequest): Promise<AxiosResponseType<{}>> => {
  return Axios.post('/api/exercise/save-practice-record-info', data);
};

