import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';
import i18n from '@/locales/init';
import { getListeningRecordList, getReadingRecordList, getWritingTask1EvaluationList, getWritingTask2EvaluationList, getWritingTask2PracticeList_1, getWritingTask2SampleEssayList } from '@/api/history';
import { historyListHOC } from '../components/historyListHOC';
import { WritingTask2PracticeItem } from '../PracticeHistory/WritingTask2PracticeItem';
import { ReadingRecordItem } from '../PracticeHistory/ReadingRecordItem';
import { ListeningRecordItem } from '../PracticeHistory/ListeningRecordItem';
import { Layout1 } from '../components/Layout1';
import { WritingTask1EvaluationItem } from '../WritingTask1EvaluationHistory/WritingTask1EvaluationItem';
import { WritingTask2EvaluationItem } from '../WritingTask2EvaluationHistory/WritingTask2EvaluationItem';
import { WritingTask2SampleEssayItem } from '../SampleEssayHistory/WritingTask2SampleEssayItem';
import style from './index.module.less';

interface IHistoryOnHomePage{
  type?: 'practice'| 'writing';
}
export const HistoryOnHomePage = React.memo((props: IHistoryOnHomePage) => {
  const [currentKey, setCurrentKey] = useState<string>('');
  const param = useParams();
  const type = props.type || 'practice' ;
  const navigate = useNavigate();
  const CompList = useMemo(() => {
    return type === 'practice' ? [
      { key: 'reading', 
        label: i18n.t('common.dauyan_history_reading'), 
        Component: historyListHOC({ 
          dataSetKey: 'readingRecordList', 
          itemRenderer: ReadingRecordItem, 
          fetchMethod: getReadingRecordList,
          onHomePage: true
        }),
        route: 'practice/1'
      },
      { key: 'listening', 
        label: i18n.t('common.dauyan_history_listening'), 
        Component: historyListHOC({ 
          dataSetKey: 'listenigRecordList', 
          itemRenderer: ListeningRecordItem, 
          fetchMethod: getListeningRecordList,
          onHomePage: true
        }),
        route: 'practice/2'
      },
      {
        key: 'writing',
        label: i18n.t('common.dauyan_history_writing'),
        Component: historyListHOC({
          dataSetKey: 'writingPracticeTask2List',
          itemRenderer: WritingTask2PracticeItem,
          fetchMethod: getWritingTask2PracticeList_1,
          onHomePage: true
        }),
        route: 'practice'
      },
    ] : [
      {
        key: 'task2',
        label: i18n.t('common.dauyan_task2_correct'),
        Component: historyListHOC({
          dataSetKey: 'writingEvaluationTask2List',
          itemRenderer: WritingTask2EvaluationItem,
          fetchMethod: getWritingTask2EvaluationList,
          onHomePage: true
        }),
        route: 'evaluation'
      },
      { key: 'task1', 
        label: i18n.t('common.dauyan_task1_correct'), 
        Component: historyListHOC({ 
          dataSetKey: 'writingEvaluationTask1List',
          itemRenderer: WritingTask1EvaluationItem,
          fetchMethod: getWritingTask1EvaluationList,
          onHomePage: true
        }),
        route: 'essay'
      },
      { key: 'sample', 
        label: i18n.t('common.dauyan_personal_sample_essay'), 
        Component: historyListHOC({ 
          dataSetKey: 'writingSampleEssayTask2List',
          itemRenderer: WritingTask2SampleEssayItem,
          fetchMethod: getWritingTask2SampleEssayList,
          onHomePage: true
        }),
        route: 'sample_essay'
      },
    ];
  }, []);

  useEffect(() => {
    setCurrentKey(CompList[0].key);
  }, [CompList]);
  

  const currentBody = useMemo(() => {
    const ele = CompList.find((ele) => ele.key === currentKey);
    if (ele?.Component) {
      return <ele.Component />;
    }
  }, [currentKey]);

  useEffect(() => {
    setCurrentKey(CompList[0].key);
  }, []);

  useEffect(()=>{
    if (param.type){
      setCurrentKey(CompList[Number(param.type)].key);
    }
  },[param.type]);

  const headerNode = useMemo(() => {
    return <div className={style.list}>
      {
        CompList.map((ele) => {
          const active = ele.key === currentKey;
          const handleClick = () => setCurrentKey(ele.key);
          return <div className={style.item} key={ele.key} data-active={active} onClick={handleClick}>{ele.label}</div>;
        })
      }
      <div className={classNames(style.more)} onClick={()=>{
        console.log(currentKey);
        const ele = CompList.find((ele) => ele.key === currentKey);
        if (ele){
          console.log(ele.route);
          navigate('history/' + ele.route);
        }
      }}>{i18n.t('common.dauyan_more')}<span>{`${' >'}`}</span></div>
    </div>;
  }, [currentKey]);

  return <Layout1
    header={headerNode}
    body={currentBody}
  />;
});
