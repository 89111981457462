export const isMobile = () => {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
};

export const isNative = () => {
  // const p = jsCookie.get('platform');
  // return p === 'android' || p === 'ios';
  return !!(window as any).X5WebView || !!window.flutter_inappwebview;
};


// 白名单
const rooterWhitelist = [
  '/written-exam'
];
export const isRooterWhitelist = () => {
  const path = window.location.pathname;
  return rooterWhitelist.some(d => path.indexOf(d) !== -1);
};