import Axios from 'axios';
import {AxiosResponseType, post} from '@/utils/network/axios-helper';
import { Criterion, Problem } from './feedback';

export interface ISaveEvaluationImagesProps {
  /**
   * 测评记录ID
   */
  evaluation_id: number;
  /**
   * 图片地址数组
   */
  file_url: string[];
  /* type 1测评记录 2：大作文批改 3小作文批改 */
  type: number;
}

export interface ISaveEvaluationImagesRes {
}


/**
 * 保存笔试图片
 */

export const saveEvaluationImages = async (param: ISaveEvaluationImagesProps): Promise<AxiosResponseType<ISaveEvaluationImagesRes>> => {
  return Axios.post('/api/exercise/save-evaluation-img', param);
};

export interface IGetEvaluationImages_Response {
  evaluation_id: number;
  file_url: Array<{ id: number; url: string; }>;
}

/**
 * 获取笔试图片，需要前端进入笔试后就开始轮询。
 * @param evaluationId
 * @param type 1测评记录 2：大作文批改 3小作文批改 4小作文题目
 */
export const getEvaluationImg = async (evaluationId: number, type = 1) => {
  return post<IGetEvaluationImages_Response>('/api/exercise/get-evaluation-img', {
    evaluation_id: evaluationId,
    type,
  });
};

/**
 * 获取笔试图片识别结果
 * @param evaluationId
 * @param imgIds
 * @param type 1测评记录 2：大作文批改 3小作文批改
 */
export const getImgIdentifyResult = async (evaluationId: number, imgIds: number[], type = 1): Promise<{
  code: number;
  msg: string;
  data: {
    evaluation_id: string;
    list: {
      [id: string]: {
        id: number;
        content: string;
        // 1:识别成功 2:未识别 3:识别失败
        is_identify: number;
      };
    };
  };
}> => {
  return post('/api/exercise/get-img-identify-result', {
    evaluation_id: evaluationId,
    img_ids: imgIds,
    type,
  });
};

export interface ISaveWritingTask2EvaluationDraft_Request {
  content?: string;
  /**
   * 记录id
   */
  record_id?: number;
  /**
   * 试卷id
   */
  paper_id?: number;

  subject?: string;
}
export interface ISaveWritingTask2EvaluationDraft_Response {
  /** 当前记录 id */
  record_id: number;
  code: string;
}

/**
 * 保存评分草稿(大作文)
 */
export const saveWritingTask2EvaluationDraft = (param: ISaveWritingTask2EvaluationDraft_Request) => {
  return post<ISaveWritingTask2EvaluationDraft_Response>('/api/exercise/big-essay-save-record-info', param);
};

export interface IGetWritingTask2EvaluationDraft_Request {
  record_id: number;
}

export interface IGetWritingTask2EvaluationDraft_Response {
  paper_id: number;
  record_id: number;
  content: string;
  /**
   * 状态：1未完成 2已完成,评分未结束 3已评分
   */
  status: number;
  update_time: number;
  subject: string;
}

/**
 * 获取评分详情（大作文）
 */
export const getWritingTask2EvaluationDraft = (params: IGetWritingTask2EvaluationDraft_Request) => {
  return post<IGetWritingTask2EvaluationDraft_Response>('/api/exercise/big-essay-get-record-info', params);
};

export interface IScoringWritingTask2_Request {
  /**
   * 记录id
   */
  record_id: number;
  /**
   * 试卷id
   */
  paper_id: number;
  /**
   * 评分内容
   */
  content: string;

  subject?: string;
}

export interface IScoringWritingTask2_Response {
  record_id: number;
}

/**
 * 大作文提交评分
 * @param params 
 * @returns 
 */
export const scoringWritingTask2 = (params: IScoringWritingTask2_Request) => {
  return post<IGetWritingTask2EvaluationDraft_Response>('/api/exercise/big-essay-scoring', params);
};

export interface IGetWritingTask2ScoreStatus_Request {
  record_id: number;
}

export interface IGetWritingTask2ScoreStatus_Response {
  result: number[];
  status: boolean;
}

/**
 * 大作文评分状态
 * @param params 
 * @returns 
 */
export const getWritingTask2ScoreStatus = (params: IGetWritingTask2ScoreStatus_Request) => {
  return post<IGetWritingTask2ScoreStatus_Response>('/api/exercise/get-big-essay-scoring-result', params);
};

export interface IGetWritingTask2ScoreInfo_Request {
  record_id: number;
}

export interface IGetWritingTask2ScoreInfo_Response {
  score: number; // 分数
  essay: string; // 原文
  criteria: Criterion[]; // 评分细则
  problem_list: Problem[]; // 纠错详情
  propose: string; // 总结和建议
}

export const getWritingTask2ScoreInfo = (params: IGetWritingTask2ScoreInfo_Request) => {
  return post<IGetWritingTask2ScoreInfo_Response>('/api/exercise/get-big-essay-score-info', params);
};
