import { useEffect } from 'react';
import { ieltsWeb } from '@/utils/env';
import { logError } from '@/utils/logger/arms-config';

export const Redirect = () => {

  useEffect(() => {
    logError(`Invalid URL: ${window.location.href}`);
    setTimeout(() => {
      (window as any).location.href = ieltsWeb;
    }, 100);
  }, []);

  return <></>;
};
