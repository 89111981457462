import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import i18n from '@/locales/init';
import { getTrainingLRecordList, getTrainingRRecordList, getTrainingWRecordList } from '@/api/history';
import buttonLessModule from '../components/CatButton/cat-button.module.less';
import { Layout } from '../components/Layout';
import { historyListHOC } from '../components/historyListHOC';
import { TrainingRecordItem } from './TrainingRecordItem';

export const BasicTrainingHistory = React.memo(() => {
  const [currentKey, setCurrentKey] = useState<string>('');
  const param = useParams();
  
  const CompList = useMemo(() => {
    return [
      {
        key: 'writing',
        label: i18n.t('common.dauyan_writing'),
        Component: historyListHOC({
          dataSetKey: 'trainingWRecordList',
          itemRenderer: TrainingRecordItem,
          fetchMethod: getTrainingWRecordList,
        }),
      },
      { key: 'reading', 
        label: i18n.t('common.dauyan_reading'), 
        Component: historyListHOC({ 
          dataSetKey: 'trainingRRecordList', 
          itemRenderer: TrainingRecordItem, 
          fetchMethod: getTrainingRRecordList,
        })
      },
      { key: 'listening', 
        label: i18n.t('common.dauyan_listening'), 
        Component: historyListHOC({ 
          dataSetKey: 'trainingLRecordList', 
          itemRenderer: TrainingRecordItem, 
          fetchMethod: getTrainingLRecordList,
        })
      },
    ];
  }, []);

  useEffect(() => {
    setCurrentKey(CompList[0].key);
  }, [CompList]);
  

  const currentBody = useMemo(() => {
    const ele = CompList.find((ele) => ele.key === currentKey);
    if (ele?.Component) {
      return <ele.Component />;
    }
  }, [currentKey]);

  useEffect(() => {
    setCurrentKey(CompList[0].key);
  }, []);

  useEffect(()=>{
    if (param.type){
      setCurrentKey(CompList[Number(param.type)].key);
    }
  },[param.type]);

  const headerNode = useMemo(() => {
    return <div className={buttonLessModule.list}>
      {
        CompList.map((ele) => {
          const active = ele.key === currentKey;
          const handleClick = () => setCurrentKey(ele.key);
          return <div className={buttonLessModule.item} key={ele.key} data-active={active} onClick={handleClick}>{ele.label}</div>;
        })
      }
    </div>;
  }, [currentKey]);

  return <Layout
    title={i18n.t('common.dauyan_practice_history')}
    header={headerNode}
    body={currentBody}
  />;
});
