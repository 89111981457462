import React from 'react';
import classnames from 'classnames';
import { UserInfo } from '@/modules/UserInfo/lazy';
import { AdviceTel } from '@/modules/PageFramework/AdviceTel';
import { DownloadApp } from '@/modules/PageFramework/DownloadApp';
import { Breadcrumb } from '../Breadcrumb/';
import css from './home.header.module.less';

export const HomeHeader = () => {
  return (
    <div className={classnames(css['container'])}>
      <div className={classnames(css['left'])}>
        <Breadcrumb />
      </div>
      <div className={classnames(css['right'])}>
        <DownloadApp className={css['right_item']} />
        <AdviceTel className={css['right_item']} />
        <UserInfo />
      </div>
    </div>
  );
};