import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWritingIdeas, saveWritingIdeas } from '@/api/writing/writingIdeas';

const initialState:{
  id:string; // paper_id
  content: string;
} = {
  content: '',
  id: ''
};

export const initWritingIdeas = createAsyncThunk('writingIdeas/initWritingIdeas', async (
  params: {id: string; type: number;}
) => {
  const { id, type } = params;
  const info = await getWritingIdeas({
    paper_id: Number(id),
    type: Number(type)
  }); // 
  const data = {content: ''};
  if (info.data){
    data.content = info.data.content;
  }
  return data;
});

export const setWritingIdeas = createAsyncThunk('writingIdeas/saveWritingIdeas', async (
  params: {id: string; content:string;type:number;}
) => {
  const {id, content, type} = params;
  console.log('保存更改', content);
  try {
    await saveWritingIdeas({
      content: content,
      paper_id: Number(id),
      type: Number(type)
    });
  } catch (err) {
    console.log(err);
  }
  
});

export const writingIdeasSlice = createSlice({
  name: 'questionReview',
  initialState,
  reducers: {
    setContent: (state,action:{payload: string;})=>{
      state.content = action.payload;
    },
    appendContent: (state,action:{payload: string;})=>{
      state.content = state.content + action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initWritingIdeas.fulfilled,(state,action)=>{
        state.content = action.payload.content;
      });
  }
});
export const WritingIdeasActions = writingIdeasSlice.actions;

