import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFeedbackDetail, getFeedbackInfo, getCompareEssay, getSharedFeedbackInfo, getSharedFeedbackDetail } from '@/api/writing/feedback';
import type { IProblem } from '@/modules/Writing/ResultNext/writing-result.type';

export enum ProblemType {
  show_type_problem_tr = 'show_type_problem_tr',
  show_type_problem_cc = 'show_type_problem_cc',
  show_type_problem_lr = 'show_type_problem_lr',
  show_type_problem_gra = 'show_type_problem_gra',
  show_type_problem_repeat_word = 'show_type_problem_repeat_word',
}

export interface IRange {
  index: number;
  length: number;
}


export interface CriterionSubItem {
  id: number;
  name: string;
  /* 批改问题数量 */
  problem_num: number;
  score: number;
  tips: string;
}

export interface Criterion {
  id: number;
  /* 名称 */
  name: string;
  /* 得分 */
  score: number;
  /* 鼠标放上后提示*/
  tips: string;
  children: CriterionSubItem[] | null;
}

export interface IFeedbackState {
  feedbackId?: number;
  encodeFeedbackId?: number;
  needAuth: boolean;
  practiceInfo?: {
    title: string;
    content: string; // 题目内容
    outline: string; // 草稿
    text: string; // 正文
    problem: IProblem[];
  } | null;
  scoreDetail?: {
    /* 评分标准列表 */
    criteria: Criterion[];
    /* 评价与建议 */
    propose: string;
    /* 总得分 */
    score: number;
    problem_list: IProblem[];
    essay: string;
  };
  markFinished: boolean; // 评分结束
  needRemark: boolean; // 需要重新评分
  showModelText: boolean; // 展示范文
  modelText?: string; // 生成好的范文

  feedbackDiff?: any[];
}

const initialState: IFeedbackState = { practiceInfo: null, needAuth: true, showModelText: false, markFinished: false, needRemark: false, };

export const fetchTopicInfo = createAsyncThunk('feedback/fetchTopicInfo', async (recordId: number, {
  getState, rejectWithValue,
}) => {
  const feedback = (getState() as any).feedback as IFeedbackState;

  if (!feedback) {
    rejectWithValue(null);
    return;
  }

  const res: any = feedback.needAuth ? await getFeedbackInfo(recordId) : await getSharedFeedbackInfo(recordId);

  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  return {
    recordId,
    practiceInfo: res.data,
  };
});

export const fetchFeedbackInfo = createAsyncThunk('feedback/fetchFeedbackInfo', async (recordId: number, {
  getState, rejectWithValue,
}) => {
  const feedback = (getState() as any).feedback as IFeedbackState;

  if (!feedback) {
    rejectWithValue(null);
    return;
  }

  const res: any = feedback.needAuth ? await getFeedbackDetail(recordId) : await getSharedFeedbackDetail(recordId);

  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  return {
    recordId,
    scoreDetail: res.data,
  };
});

export const fetchDiffInfo = createAsyncThunk('', async (_, { getState, rejectWithValue }) => {
  const feedback = (getState() as any).feedback as IFeedbackState;

  if (!feedback) {
    rejectWithValue(null);
    return;
  }
  const res: any = await getCompareEssay(feedback.feedbackId as number);
  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  return {
    diff: res.data,
  };
});


export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setTopicInfo: (state, action: { payload: { id: number; title: string; content: string; text: string; }; }) => {
      state.feedbackId = action.payload.id;
      state.practiceInfo = {
        title: action.payload.title,
        content: action.payload.content,
        outline: '',
        text: action.payload.text,
        problem: [],
      };
    },
    reset: (state) => {
      state.markFinished = false;
      state.practiceInfo = null;
      state.needAuth = true;
      state.needRemark = false;
      state.showModelText = false;
      state.feedbackId = undefined;
      state.practiceInfo = undefined;
      state.scoreDetail = undefined;
      state.feedbackDiff = undefined;
      state.encodeFeedbackId = undefined;
    },
    setScoreDetail: (state, action: { payload: {
      /* 评分标准列表 */
      criteria: Criterion[];
      /* 评价与建议 */
      propose: string;
      /* 总得分 */
      score: number;
      problem_list: IProblem[];};
    }) => {
      state.scoreDetail = {
        ...action.payload,
        essay: state.practiceInfo?.text || '',
      };
    },
    setFeedbackId: (state, action: { payload: number; }) => {
      if (state.practiceInfo && state.feedbackId !== action.payload) {
        state.practiceInfo = undefined;
      }
      state.feedbackId = action.payload;
      state.showModelText = false;
      state.modelText = undefined;
      state.needRemark = false;
    },
    setNeedAuth: (state, action: { payload: boolean; }) => {
      state.needAuth = !!action.payload;
    },
    setModelTextVisible: (state, action: { payload: boolean; }) => {
      state.showModelText = action.payload;
    },
    setModelTextAction: (state, action: { payload: string; }) => {
      state.modelText = action.payload;
    },
    setMarkFinished: (state) => {
      state.markFinished = true;
    },
    setNeedRemark: (state, action: { payload: boolean; }) => {
      state.needRemark = action.payload;
    },
    remarkAction: (state) => {
      state.markFinished = false;
      state.needRemark = false;
      state.scoreDetail = undefined;
    },
    setFeedbackData: (state, action:{payload:IFeedbackState;}) =>{
      // state = action.payload;
      state.practiceInfo = action.payload.practiceInfo;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbackInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.feedbackId = action.payload.recordId;
          state.scoreDetail = action.payload.scoreDetail;
          // state.needRemark = action.payload.scoreDetail.criteria.length < 4;
        }
      });

    builder
      .addCase(fetchTopicInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.feedbackId = action.payload.recordId;
          state.encodeFeedbackId = action.payload.practiceInfo.encode_id;
          state.practiceInfo = action.payload.practiceInfo;
        }
      });

    builder.addCase(fetchDiffInfo.fulfilled, (state, action) => {
      state.feedbackDiff = action.payload?.diff.list || [];
    });

  },
});

export default feedbackSlice;
export const { setFeedbackId, setModelTextVisible, setModelTextAction, setFeedbackData, setMarkFinished, remarkAction, setNeedRemark, setNeedAuth } = feedbackSlice.actions;
export const FeedbackAction = feedbackSlice.actions;
