import { createSlice } from '@reduxjs/toolkit';
import { IGetEvaluationRecordResponse } from '@/api/writing/topicList';

export interface IEvaluationRecordSliceStateType {
  evaluationRecord: IGetEvaluationRecordResponse | null;
}

const initialState: IEvaluationRecordSliceStateType = {
  evaluationRecord: null
};

const evaluationRecordSlice = createSlice({
  name: 'evaluationRecord',
  initialState,
  reducers: {
    setEvaluationRecordUserInfo: (state, action: { payload: IGetEvaluationRecordResponse | null; }) => {
      state.evaluationRecord = action.payload;
    },
    setEvaluationRecordContent: (state, action: { payload: string; }) => {
      if (state.evaluationRecord){
        state.evaluationRecord.content = action.payload;
      }
    },
  },
});

export const { setEvaluationRecordUserInfo, setEvaluationRecordContent } = evaluationRecordSlice.actions;

export default evaluationRecordSlice;
