import React from 'react';
import { ReactNode } from 'react';
import lessModule from './layout.module.less';

export interface ILayoutProps {
  header?: ReactNode;
  body: ReactNode;
}

export const Layout1 = React.memo<ILayoutProps>(({
  header, body
}) => {

  return <div className={lessModule.wrapper}>
    <div className={lessModule.header}>
      {
        header ? <div className={lessModule.headerChildren}>{header}</div> : null
      }
    </div>
    <div className={lessModule.body}>{body}</div>
  </div>;
});
