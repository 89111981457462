import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSearchConfig, getListeningPaperList, getListeningPaperListJiJing, getSearchListeningPaperList } from '@/api/listen/list';
import { ITopicListState } from './readingListSlice';

type IMethod = number; // 练习方式 1套题 2题型 3话题

const initialState: ITopicListState = {
  fechtingList: false,
  fechtingSearch: false,
  pid: 1,
  method: 1,
  type: 0,
  search_content: '',
  searchConfig: {
    jian_ya: [],
    ji_jing: [],
    yu_ce: []
  },
  jianyaList: {
    total: 0,
    page: 1,
    list: []
  },


  jijingList: {
    total: 0,
    page: 1,
    list: []
  },
  searchList: {
    total: 0,
    page: 1,
    list: []
  },
  reloadState: 0,
};

// 获取筛选条件列表
export const getListeningSearchListAsync = createAsyncThunk('listeningList/getSearchList', async (_, {
  rejectWithValue,
}) => {
  try {
    const res = await getSearchConfig();
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }
    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }

});

/**
 * 获取剑雅 套题 列表
 */
export const getJianyaListeningList = createAsyncThunk('listeningList/getJianyaListeningList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = (getState() as any).listeningList as ITopicListState;
    const res = await getListeningPaperList({
      type: state.type,
      page: state.jianyaList.page,
      page_size: 2
    });
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});
/**
 * 获取机经 套题 列表
 */
export const getJijingListeningList = createAsyncThunk('listeningList/getJijingListeningList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = ((getState() as any).listeningList) as ITopicListState;
    const res = await getListeningPaperListJiJing({ unit: state.type, page: state.jijingList.page, page_size: 6 });
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});

/**
 * 根据条件获取题型或话题的列表
 */
export const getSearchListeningList = createAsyncThunk('listeningList/getSearchListeningList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = (getState() as any).listeningList as ITopicListState;
    const params = {
      pid: state.pid,
      search_content: state.search_content,
      topic: 0,
      type: 0,
      page: state.searchList.page,
      page_size: 10
    };
    if (state.method === 2){
      params.type = state.type;
    } else if (state.method === 3){
      params.topic = state.type;
    }
    const res = await getSearchListeningPaperList(params);
    if (res.code !== 200) {
      rejectWithValue(null);
      return { total: 0, list: [] };
    }
    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});

export const listeningListSlice = createSlice({
  name: 'listeningList',
  initialState,
  reducers: {
    setPid: (state, action: {payload: number;})=>{
      state.pid = action.payload;
      state.type = 1;
      state.method = 1;
      if (action.payload === 1){
        state.jianyaList.page = 1;
        state.type = 0;
      } else if (action.payload === 2){
        state.jijingList.page = 1;
        state.type = 0;
      } else if (action.payload === 3){
        state.searchList.page = 1;
        state.type = 1;
      }
    },
    
    setSearchMethod: (state, action: { payload: {method: IMethod; type: number;}; }) => {
      state.method = action.payload.method;
      state.type = action.payload.type;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setSearchContent: (state, action: { payload: string; }) => {
      state.search_content = action.payload;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setSearchType: (state, action: { payload: number; }) => {
      state.type = action.payload;
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    setReload: (state) => {
      state.reloadState = Math.random();
      state.jianyaList.page = 1;
      state.searchList.page = 1;
      state.jijingList.page = 1;
    },
    getNextPage: (state) => {
      if (state.pid === 1 && state.method === 1) {
        state.jianyaList.page += 1;
       
      } else if (state.pid === 2 && state.method === 1){
        state.jijingList.page += 1;
      } else {
        state.searchList.page += 1;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJianyaListeningList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getJianyaListeningList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.jianyaList.page === 1 ? action.payload.list : state.jianyaList.list.concat(action.payload.list);
          state.jianyaList.list = newList;
          state.jianyaList.total = action.payload.total;
        }
      })

      .addCase(getJijingListeningList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getJijingListeningList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.jijingList.page === 1 ? action.payload.list : state.jijingList.list.concat(action.payload.list);
          state.jijingList.list = newList;
          state.jijingList.total = action.payload.total;
        }
      })

      .addCase(getSearchListeningList.pending, (state) => {
        state.fechtingList = true;
      })
      .addCase(getSearchListeningList.fulfilled, (state, action) => {
        state.fechtingList = false;
        if (action.payload) {
          const newList = state.searchList.page === 1 ? action.payload.list : state.searchList.list.concat(action.payload.list);
          state.searchList.list = newList;
          state.searchList.total = action.payload.total;
        }
      })

      .addCase(getListeningSearchListAsync.pending, (state) => {
        state.fechtingSearch = true;
      })
      .addCase(getListeningSearchListAsync.fulfilled, (state, action) => {
        state.fechtingSearch = false;
        if (action.payload) {
          state.searchConfig = action.payload;
        }
      });
  },
});

export const ListeningListActions = listeningListSlice.actions;

