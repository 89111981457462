import React from 'react';
import { Breadcrumb as BC } from 'antd';
import { useAppSelector } from '@/stores';
import lessModule from './bread.module.less';

export const Breadcrumb: React.FC = () => {
  const menu = useAppSelector(state => state.user.breadcrumbs);
  return <BC
    separator=">"
    items={menu}
    className={lessModule.bread}
  />;
};