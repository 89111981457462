import { createIconComp } from './Core/createIconComp';
const icon = `<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.97462 12.7802C11.4005 12.7802 14.1777 11.8264 14.1777 10.6499C14.1777 9.47331 11.4005 8.51953 7.97462 8.51953C4.54872 8.51953 1.77148 9.47331 1.77148 10.6499C1.77148 11.8264 4.54872 12.7802 7.97462 12.7802Z" fill="#F5C164"/>
<g filter="url(#filter0_i_1287_8664)">
<path d="M7.97462 12.7802C11.4005 12.7802 14.1777 11.8264 14.1777 10.6499C14.1777 9.47331 11.4005 8.51953 7.97462 8.51953C4.54872 8.51953 1.77148 9.47331 1.77148 10.6499C1.77148 11.8264 4.54872 12.7802 7.97462 12.7802Z" fill="#F5C164"/>
</g>
<path d="M0.941406 3.87109L12.5573 10.6023C12.7808 10.7319 12.8607 11.0235 12.7329 11.2503C12.6531 11.3961 12.4934 11.4933 12.3258 11.4933H2.70575C2.23472 11.4933 1.83555 11.1369 1.77168 10.659L0.941406 3.87109Z" fill="#F5C164"/>
<path d="M15.0247 3.87109L14.1864 10.659C14.1305 11.1369 13.7313 11.4933 13.2523 11.4933H3.64827C3.3928 11.4933 3.17725 11.2827 3.17725 11.0154C3.17725 10.8453 3.26506 10.6833 3.41675 10.6023L15.0247 3.87109Z" fill="#F5C164"/>
<path d="M14.092 3.39331C14.092 3.91982 14.5152 4.34913 15.0341 4.34913C15.553 4.34913 15.9761 3.91982 15.9761 3.39331C15.9761 2.86681 15.553 2.4375 15.0341 2.4375C14.5072 2.4375 14.092 2.86681 14.092 3.39331Z" fill="white"/>
<path d="M0.000244141 3.39331C0.000244141 3.91982 0.423366 4.34913 0.94229 4.34913C1.46121 4.34913 1.88434 3.91982 1.88434 3.39331C1.88434 2.86681 1.46121 2.4375 0.94229 2.4375C0.423366 2.4375 0.000244141 2.86681 0.000244141 3.39331Z" fill="url(#paint0_linear_1287_8664)"/>
<g opacity="0.752059" filter="url(#filter1_f_1287_8664)">
<path d="M2.02546 10.7273C2.04145 10.7315 2.05745 10.7357 2.07344 10.7441C2.71324 11.016 4.64064 11.2126 6.91992 11.2126C6.74058 9.27928 6.49926 7.9157 6.19599 7.12182C6.07565 6.80683 5.68154 6.50711 5.01366 6.22266L1.74555 10.0497C1.46563 10.2756 1.5856 10.5809 2.02546 10.7273Z" fill="#DC9F2B"/>
</g>
<path d="M7.98274 1.43359V11.7451H7.97476C5.63561 11.7451 3.67168 11.3481 3.08091 10.7973C2.83342 10.6272 2.66577 10.3356 2.66577 10.0116C2.66577 9.82532 2.72166 9.64712 2.81746 9.49322L7.98274 1.43359Z" fill="url(#paint1_linear_1287_8664)"/>
<g filter="url(#filter2_f_1287_8664)">
<path d="M13.9423 10.7273C13.9263 10.7315 13.9103 10.7357 13.8943 10.7441C13.2545 11.016 11.3271 11.2126 9.04785 11.2126C9.2272 9.27928 9.46851 7.9157 9.77179 7.12182C9.89212 6.80683 10.2862 6.50711 10.9541 6.22266L14.2222 10.0497C14.5021 10.2756 14.3822 10.5809 13.9423 10.7273Z" fill="#DC9F2B"/>
</g>
<path d="M12.8685 10.8054C12.8526 10.8135 12.8366 10.8216 12.8206 10.8378C12.182 11.3643 10.2579 11.7451 7.98267 11.7451V1.43359L13.1479 9.49322C13.4274 9.93063 13.3076 10.5219 12.8685 10.8054Z" fill="#F9CF7E"/>
<g filter="url(#filter3_i_1287_8664)">
<path d="M12.8685 10.8054C12.8526 10.8135 12.8366 10.8216 12.8206 10.8378C12.182 11.3643 10.2579 11.7451 7.98267 11.7451V1.43359L13.1479 9.49322C13.4274 9.93063 13.3076 10.5219 12.8685 10.8054Z" fill="#F9CF7E"/>
</g>
<path d="M7.04175 0.955815C7.04175 1.48232 7.46487 1.91163 7.98379 1.91163C8.50272 1.91163 8.92584 1.48232 8.92584 0.955815C8.92584 0.429307 8.50272 0 7.98379 0C7.46487 0 7.04175 0.429307 7.04175 0.955815Z" fill="white"/>
<defs>
<filter id="filter0_i_1287_8664" x="1.77148" y="8.51953" width="12.4062" height="4.79421" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.532495"/>
<feGaussianBlur stdDeviation="0.798743"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.302512 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1287_8664"/>
</filter>
<filter id="filter1_f_1287_8664" x="1.06248" y="5.69016" width="6.38994" height="6.05327" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.266248" result="effect1_foregroundBlur_1287_8664"/>
</filter>
<filter id="filter2_f_1287_8664" x="8.51536" y="5.69016" width="6.38994" height="6.05327" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.266248" result="effect1_foregroundBlur_1287_8664"/>
</filter>
<filter id="filter3_i_1287_8664" x="7.98267" y="1.43359" width="5.31567" height="10.845" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.532495"/>
<feGaussianBlur stdDeviation="0.532495"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.313745 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1287_8664"/>
</filter>
<linearGradient id="paint0_linear_1287_8664" x1="0.281769" y1="2.4375" x2="1.60281" y2="4.34913" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#FFE19F"/>
</linearGradient>
<linearGradient id="paint1_linear_1287_8664" x1="327.122" y1="110.379" x2="109.617" y2="1713.91" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFF3D8"/>
<stop offset="1" stop-color="#FFE19F"/>
</linearGradient>
</defs>
</svg>
`;

export const VipIcon2 = createIconComp({ icon });