import React, { HTMLAttributes } from 'react';
import i18n from '@/locales/init';
import lessModule from './section.module.less';

export interface IEntrySectionProps {
  key: string;
  text_key: string;
  ICON: (props: HTMLAttributes<HTMLDivElement>) => JSX.Element;
  target: string;
  growCount: number;
  totalCount: number;
}

export const generateSectionEntry = ({
  key, text_key, ICON, target, growCount, totalCount
}: IEntrySectionProps) => {
  const Comp = React.memo(() => {
    return <>
      <ICON className={lessModule.entry_icon}></ICON>
      <div className="section_title">
        {i18n.t(text_key)}
      </div>
      <div className={lessModule.entry_count}>
        <span className={lessModule.entry_count_total}>{totalCount}</span>
        <span className={lessModule.entry_count_grow}>{i18n.t('common.dauyan_new_add_count', { count: growCount })}</span>
      </div>
      <div className={lessModule.entry_label}>
        <span>{i18n.t('common.dauyan_jianya')}</span>
        <span>{i18n.t('common.dauyan_machine')}</span>
        <span>{i18n.t('common.dauyan_predict')}</span>
      </div>
    </>;
  });

  return {
    key,
    Comp,
    target
  };
};

export const EntrySectionClassName = lessModule.section_entries;
