import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import jsCookie from 'js-cookie';
import LanguageDetector from 'i18next-browser-languagedetector';

import dayjs from 'dayjs';
import { authCookieDomain } from '@/utils/env';
import '@/packages/h5-writing-score/locale';
import '@dauyan/ai-chat/locale';


import * as enUsTrans from './en-US';
import * as zhCnTrans from './zh-CN';

export enum Lang {
  zhCn = 'zh-CN',
  enUs = 'en-US',
}

export const LOCALS = Object.values(Lang);


export function getLanguage() {
  const cookieLang = jsCookie.get('lang') as (Lang | undefined);
  if (cookieLang && LOCALS.includes(cookieLang)) {
    return cookieLang;
  }
  const lang = window.navigator.language as Lang;
  if (LOCALS.includes(lang)) {
    jsCookie.set('lang', lang, {
      expires: 365,
      domain: authCookieDomain
    });
    return lang;
  }
  return LOCALS[0];
}

export function setLanguage(lang: Lang) {
  // 因为之前的 bug，可能用户的 cookie 里面有多个 lang，所以先清除一下
  jsCookie.remove('lang', {
    domain: authCookieDomain
  });
  jsCookie.remove('lang');

  jsCookie.set('lang', lang, {
    expires: dayjs().add(365 * 10, 'day').toDate(),
    domain: authCookieDomain
  });
}

export const COOKIE_KEY = 'lang';

const resources = {
  'zh-CN': {
    translation: zhCnTrans,
  },
  'en-US': {
    translation: enUsTrans,
  },
};

export const LANG_MAP = [
  {
    // eslint-disable-next-line i18n/no-chinese-character
    label: '中文',
    value: Lang.zhCn,
  },
  {
    label: 'English',
    value: Lang.enUs,
  },
];

export const LANGS = Object.values(Lang);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguage(),
    fallbackLng: 'zh-CN',
    load: 'currentOnly',
    detection: {
      order: ['navigator', 'cookie'],
    },
  });

(window as any).i18n = i18n;

/**
 * 获取初始化的浏览器语言
 * @returns 
 */
export const getInitLang = () => {
  const cookieLang = jsCookie.get(COOKIE_KEY) as Lang;
  if (cookieLang) {
    return cookieLang;
  }
  const computerLang = navigator.language;
  const matched = LANGS.find((item) =>
    item.toLocaleLowerCase().includes(computerLang)
  );
  return matched || Lang.zhCn;
};

i18n.changeLanguage(getInitLang());


export const checkChinese = () => {
  return getLanguage() === Lang.zhCn;
};

export default i18n;

