import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { ReadingIcon } from '@/components/Icon/ReadingIcon';
import { ListeningIcon } from '@/components/Icon/ListeningIcon';
import { WritingIcon } from '@/components/Icon/WritingIcon';
import i18n from '@/locales/init'; 
import { HistoryOnHomePage } from '@/modules/List/HistoryList/HistoryOnHomePage';
import lessModule from './index.module.less';
import { SectionUser, UserSectionClassName } from './SectionUser';
import { SectionAI, SectionAIClassName } from './SectionAI';
import { SectionTarget, SectionTargetClassName } from './SectionTarget';
import { EntrySectionClassName, generateSectionEntry, IEntrySectionProps } from './SectionEntry';
import { SectionCurrent, SectionCurrentClassName } from './SectionCurrent';

const entryList: IEntrySectionProps[] = [
  { key: 'reading', text_key: i18n.t('common.dauyan_reading_ai_practice'), ICON: ReadingIcon, target: '/reading-list', growCount: 234, totalCount: 4865 },
  { key: 'listening', text_key: i18n.t('common.dauyan_listening_ai_practice'), ICON: ListeningIcon, target: '/listening-list', growCount: 221, totalCount: 8478 },
  // { key: 'speaking', text_key: i18n.t('common.dauyan_speaking_ai_practice'), icon: SpeakingIcon, target: '', growCount: 230, },
  { key: 'writing', text_key: i18n.t('common.dauyan_writing_ai_practice'), ICON: WritingIcon, target: '/writing-list/1', growCount: 10, totalCount: 565 },
];

const EntrySections = entryList.map((ele) => {
  return generateSectionEntry(ele);
});

export const HomePage = () => {

  const navigate = useNavigate();

  return <div className={lessModule.wrapper}>
    <div className={lessModule.row}>
      <section className={classNames(lessModule.section, UserSectionClassName)}><SectionUser /></section>
    </div>
    <div className={classNames(lessModule.row, lessModule.row_target)}>
      <section className={SectionTargetClassName}><SectionTarget /></section>
      <section className={SectionCurrentClassName}><SectionCurrent /></section>
    </div>
    <div className={classNames(lessModule.row, lessModule.row_entries)}>
      {
        EntrySections.map((ele) => {
          return <section key={ele.key} className={classNames(lessModule.section, EntrySectionClassName)} onClick={() => navigate(ele.target)}>
            <ele.Comp />
          </section>;
        })
      }
    </div>
    <div className={classNames(lessModule.row, lessModule.row_history)}>
      <section>
        <HistoryOnHomePage></HistoryOnHomePage>
      </section>
      <section>
        <HistoryOnHomePage type='writing'></HistoryOnHomePage>
      </section>
    </div>
    <div className={lessModule.row}>
      <section className={classNames(lessModule.section, SectionAIClassName)}><SectionAI /></section>
    </div>
  </div>;
};
