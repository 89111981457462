import qs from 'query-string';
import { useParams } from 'react-router';
import { IBasicQuestion } from '@/api/basic/list';
import store, { useAppSelector } from '@/stores';
import i18n from '@/locales/init';
import { StatusLabel } from '../../HistoryList/components/StatusLabel';
import { TrainingResult } from '../TraningResult';
import style from './index.module.less';
interface IBasicQuestionProps {
  data: IBasicQuestion;
}

const generateSearch = (data: any) => {
  const keyValues = [];
  if (data.record_id && data.record_status === 1) {
    keyValues.push(['record_id', data.record_id]);
  }

  const selected = store.getState().basicTraingList.selected;
  const payload: any = {};
  selected.forEach((item: any) => {
    payload[item.key] = item.value;
  });
  const queryStr = qs.stringify(payload);
  const search = `${keyValues.length > 0 ? '&' : '?'}search=${btoa(unescape(encodeURIComponent(queryStr)))}`;
  return (keyValues.length > 0 ? `?${keyValues.map((ele) => {
    return `${ele[0]}=${ele[1]}`;
  }).join('&')}` : '') + search;
};

/**
 * 获取阅读题目的路径
 * @param data 
 * @param type 
 * @returns 
 */
export const getReadingQustionPath = (data: any, noSearch?:boolean) => {
  let p = '';
  switch (data.type) {
    case 1: {
      p = 'pos';
      break;
    }
    case 2: {
      p = 'sentence';
      break;
    }
    default:
      return '';
  }

  const query = noSearch ? `?record_id=${data.record_id}`: generateSearch(data);
  const path = `${window.location.origin}/basic-training/#/reading/${p}/${data.id}${query}`;
  return path;
};

export const getWritingQustionPath = (data: any, noSearch?:boolean) => {
  let p = '';
  switch (data.type) {
    case 1: {
      p = 'conjunction';
      break;
    }
    case 2: {
      p = 'translate';
      break;
    }
    default:
      return '';
  }
  const query = noSearch ? `?record_id=${data.record_id}`: generateSearch(data);
  const path = `${window.location.origin}/basic-training/#/writing/${p}/${data.id}${query}`;
  return path;
};

export const getListeninQustionPath = (data: any, noSearch?:boolean) => {
  const query = noSearch ? `?record_id=${data.record_id}`: generateSearch(data);
  const path = `${window.location.origin}/basic-training/#/listening/blank/${data.id}${query}`;
  return path;
};

const statusLabels = [i18n.t('common.dauyan_not_start'), i18n.t('common.dauyan_not_done'), i18n.t('common.dauyan_some_complete')];
const buttonLables = [i18n.t('答题'), i18n.t('继续答题'), i18n.t('重做')];
export const BasicQuestion = (props: IBasicQuestionProps) => {
  const state = useAppSelector(store => store.basicTraingList);
  const { selected, fields } = state;
  const { data } = props;
  const status = data.record_status;
  const statusLabel = statusLabels[status] || status.toString();
  const params = useParams();
  const trainingType = Number(params.type);
  const handleClick = () => {
    switch (trainingType) {
      case 1: {
        const path = getWritingQustionPath(data);
        window.open(path);
        break;
      }
      case 2: {
        const path = getReadingQustionPath(data);
        window.open(path);
        break;
      }
      case 3: {
        const path = getListeninQustionPath(data);
        window.open(path);
        break;
      }
      default: {
        console.error('Unhandled question type', trainingType);
      }
    }
  };

  let fs = fields;
  if (!selected.find(v=> v.key === 'grammar')) {
    fs = fields.filter(v => v!=='grammar');
  }
  return <div className={style.wrapper} onClick={handleClick} style={{
    gridTemplateColumns: `repeat(${fs.length + 1}, 1fr)`
  }}>
    {
      fs.map((field, index) => {
        const da = (data as any);
        let value: any = da[field + '_text'] || da[field];
        if (field === 'record_status') {
          return <div key={index} className={style.status}><StatusLabel text={statusLabel} status={status}></StatusLabel></div>;
        } else if (field === 'record_time') {
          if (value === '1970-01-01 08:00' || value === ''){
            value = '-';
          }
          return <div key={index} className={style.time}>{value}</div>;
        } else if (field === 'result') {
          value = <TrainingResult result={da.result} result_type={da.result_type} 
            status={status} schedule={da.schedule} score={da.score}/>;
        }
        return <div className={style[field]} key={index}>{value}</div>;
      })
    }
    <div className={style.button}>
      <div className={style.doButton}>{buttonLables[data.record_status] || buttonLables[0]}</div>
    </div>
  </div>;
};

