import { CSSProperties } from 'react';
import classNames from 'classnames';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { DownloadIcon } from '@/components/Icon/Download';
import { homeUrl } from '@/utils/env';
import i18n from '@/locales/init';
import style from './index.module.less';

export const DownloadApp = (props: {style?:CSSProperties;className?:string;})=>{
  return <div className={classNames(props.className, style.container)} style={props.style}>
    <SimpleButton onClick={() => window.open(`${homeUrl}download`)} className={style.button}>{i18n.t('common.dauyan_download_app')}
      <DownloadIcon className={style.icon} />
    </SimpleButton>
  </div>;
};