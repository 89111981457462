import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { IMultipleChoice } from '@/pages/Writing/sub-pages/QuestionReview/components/MultipleChoice';
import { IAnswerQuestion, answerQuestionReview, getQuestionReviewList, getQuestionReviewRecord } from '@/api/writing/questionReview';


const initialState:{
  questions: IMultipleChoice[];
  nextQuestion?: string;
  record_id?: number;
} = {
  questions: [],
};
/**
 * 拉取题目信息
 */
export const initQuestionReview = createAsyncThunk('questionReview/initQuestion', async (
  params: {id: string; type: number; record_id?: string;}
) => {
  const { id, type, record_id } = params;
  const data: {questions: IMultipleChoice[]; record_id: number;} = {
    questions: [],
    record_id: 0
  };
  try {
    const info = await getQuestionReviewList( Number(id), type); // 
    if (info.data && info.data.list){
      for (let i=0;i<info.data.list.length;i++){
        const l = info.data.list[i];
        const item: IMultipleChoice = {
          order: l.number,
          title: l.title,
          real: l.answer,
          judge: true,
          options: []
        }; 
        for (let j = 0;j<l.option.length;j++){
          const o = l.option[j];
          item.options.push({
            order: String.fromCharCode(j + 65),
            content: o.item,
            explanation: o.explanation
          });
        }
        data.questions.push(item);
      }
    }
    if (info.data && info.data.record_id !== undefined){
      data.record_id = info.data.record_id;
      console.log('record_id:', info.data.record_id);
    }
  } catch (e){
    console.log(e);
  }
  
  if (record_id !== undefined && type !== undefined){
    const info = await getQuestionReviewRecord({
      paper_id: Number(id),
      type: Number(type),
      record_id: Number(record_id)
    });
    console.log('===>', info.data.list);
    if (info.data && info.data.list){
      for (let i = 0; i < info.data.list.length; i++) {
        const item = info.data.list[i];
        const q = data.questions.find((value)=> value.order === item.question_id);
        if (q){
          q.answer = item.answer;
          q.locked = true;
        }
      }
    }
  }

  return data;
});

/**
 * 回答题目
 */
export const answerQuestion = createAsyncThunk('questionReview/answerQuestion', async (
  params: IAnswerQuestion
) => {
  const { paper_id, type, answer,list, record_id } = params;
  try {
    answerQuestionReview({
      paper_id: paper_id,
      answer: answer,
      type: Number(type) || 1,
      list: list,
      record_id: Number(record_id)
    });
  } catch (error) {
    console.log(error);
  }
});

export const questionReviewSlice = createSlice({
  name: 'questionReview',
  initialState,
  reducers: {
    /**
     * 某道题选中
     * @param state 
     * @param action 
     */
    setOptionSelected: (state, action: { payload: {order:number; aIndex:number;};}) => {
      const question = state.questions.find((value)=> value.order === action.payload.order);
      if (question){
        question.answer = action.payload.aIndex;
        question.locked = true;
      } 
    },
    /**
     * 设置答题记录Id
     * @param state 
     * @param action 
     */
    setRecordID: (state, action:{payload:number;})=>{
      state.record_id = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(initQuestionReview.fulfilled,(state, action)=>{
        state.questions = action.payload.questions;
        state.record_id = action.payload.record_id;
      });
  }
});
export const QuestionReviewActions = questionReviewSlice.actions;

