/**
 * 单个题目信息
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sleep } from '@/utils/sleep';
import { IRange } from '@/utils/range';
import type { IGuideType } from '@/modules/Writing/ArgumentsAndEvidances/arguments-and-evidances.type';
import { IpracticeInfo, getNextTopicId, getPracticeInfo } from '@/api/writing/practice';
import { IUpdateRecommendedArgumentReq, updateRecommendedArgument, updateRecommendedEvidences } from '@/api/writing/recommendedInfo';
import { getEvidences } from '@/modules/Writing/ArgumentsAndEvidances/utils/getEvidences';
import { getArgumentId } from '@/modules/Writing/ArgumentsAndEvidances/utils/getArguments';
import { mergeArgumentList } from '@/modules/Writing/ArgumentsAndEvidances/utils/merge';
import { forEachArgument, forEachDirection } from '@/modules/Writing/ArgumentsAndEvidances/utils/iterater';
import type { ReduxStateType } from '..';
import { ISearchQuery } from './topicListSlice2';

export interface ITopicTip {
  content: string;
  title: string;
  question_key: string;
}


export interface ITopicKeyword {
  id: number;
  text: string;
  type: string;
  content: string;
  range: IRange;
  ranges: IRange[] | null;
}

export interface ITopicInfo {
  id: number;
  title: string;
  content: string;
  record_id: number;
  record_content: string;
  record_outline: string;
  guide: IGuideType;
  keywords: ITopicKeyword[];
  tips: ITopicTip[];
  next_id: number;
  requirement: string;
  statement_question: string;
}

export interface ITopicType {
  info: ITopicInfo | null;
  loading: boolean; // info 加载中
  argumentsLoading: boolean; // 论点论据加载中
  evidencesLoading: boolean;
  next_id?: number;
}

const initialState: ITopicType = {
  loading: true,
  info: null,
  argumentsLoading: false,
  evidencesLoading: false,
};

// 获取初始化数据
export const fetchTopicInfo = createAsyncThunk('topic/fetchTopicInfo', async ({
  exerciseId, recordId,
}: { exerciseId: number; recordId?: number; }) => {
  const params: IpracticeInfo = { id: exerciseId };
  if (recordId) {
    params.record_id = recordId;
  }

  const response = await getPracticeInfo(params);
  return response.data;
});

export const fetchNextTopicID = createAsyncThunk('topic/fetchNextTopicID', async (
  params: { query: ISearchQuery; id: number; }
) => {
  const { query, id } = params;
  const info = await getNextTopicId({
    id: id,
    category: query.category,
    topic: query.topic,
    subject_type: query.subject_type,
    year: query.year,
  }); // 
  if (info.data.id !== undefined) {
    return info.data.id;
  }
  return 0;
});

// 更新论点论据

// 更新论据 action
export const updateRecommendedEvidenceAction = createAsyncThunk('topic/updateRecommendedEvidence', async (_, {
  getState,
  rejectWithValue,
}) => {
  const topic = (getState() as ReduxStateType).topic;
  if (!topic.info) {
    rejectWithValue(null);
    return;
  }

  const res = await updateRecommendedEvidences({
    exercises_id: topic.info.id,
    list: getEvidences(topic.info.guide),
  });

  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  await sleep(1000);

  return res.data;
});

// 更新论点 action
export const updateRecommendedArgumentAction = createAsyncThunk('topic/updateRecommendedArgument', async (isAppend: boolean, {
  getState,
  rejectWithValue,
}) => {
  const topic = (getState() as any).topic;
  if (!topic.info) {
    rejectWithValue(null);
    return;
  }

  const param: IUpdateRecommendedArgumentReq = {
    exercises_id: topic.info.id,
    list: getArgumentId(topic.info.guide),
  };

  if (isAppend) {
    param.is_append = 1;
  }

  const res = await updateRecommendedArgument(param);

  if (res.code !== 200) {
    rejectWithValue(null);
    return;
  }
  await sleep(1000);

  return {
    ...res.data,
    isAppend,
  };
});

export const shownArgumentList = createAsyncThunk('topic/shownArgumentList', async () => {
  await sleep(1000);
  return;
});

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    setNextTopicId: (state, action: { payload: number; }) => {
      state.next_id = action.payload;
    },
    resetTopic: (state) => {
      const keys = Object.keys(initialState);
      keys.forEach(key => {
        (state as any)[key] = (initialState as any)[key];
      });
    }
  },
  extraReducers: (builder) => {
    // 获取题目数据
    builder
      .addCase(fetchTopicInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTopicInfo.fulfilled, (state, action) => {
        const { payload } = action;
        state.info = payload;
        state.loading = false;
        state.evidencesLoading = true;
        state.argumentsLoading = true;
      })
      .addCase(fetchTopicInfo.rejected, (state) => {
        state.evidencesLoading = true;
        state.argumentsLoading = true;
      });

    // 更新论点论据

    // 更新论点
    builder
      .addCase(updateRecommendedArgumentAction.pending, (state, action) => {
        if (state.argumentsLoading) {
          return;
        }
        state.argumentsLoading = true;
      })
      .addCase(updateRecommendedArgumentAction.fulfilled, (state, action) => {
        if (!state.info) {
          return;
        }
        const append = !!action.payload?.isAppend;
        forEachDirection(state.info.guide, (dir) => {
          const nDir = action.payload?.list.find((aDir) => aDir.id === dir.id);
          if (!nDir) {
            return;
          }
          if (!append) {
            dir.list = nDir.list;
          } else {
            dir.list = mergeArgumentList(dir.list || [], nDir.list || []);
          }
        });
        state.argumentsLoading = false;
      })
      .addCase(updateRecommendedArgumentAction.rejected, (state, action) => {
        state.argumentsLoading = false;
      });

    // 更新论据
    builder
      .addCase(updateRecommendedEvidenceAction.pending, (state, action) => {
        state.evidencesLoading = true;
      })
      .addCase(updateRecommendedEvidenceAction.fulfilled, (state, action) => {
        if (!state.info) {
          return;
        }
        forEachArgument(state.info.guide, (arg) => {
          const nArg = action.payload?.list.find((nArg) => nArg.id === arg.id);
          if (nArg?.list) {
            arg.list = nArg.list;
          }
        });
        state.evidencesLoading = false;
      })
      .addCase(updateRecommendedEvidenceAction.rejected, (state, action) => {
        state.evidencesLoading = false;
      });

    // 首次显示论点论据
    builder
      .addCase(shownArgumentList.fulfilled, (state) => {
        state.argumentsLoading = false;
        state.evidencesLoading = false;
      });
    builder.addCase(fetchNextTopicID.fulfilled, (state, action) => {
      state.next_id = action.payload;
    });
  },
});
export const { setNextTopicId, resetTopic } = topicSlice.actions;