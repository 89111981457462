import React, { useState } from 'react';
import classnames from 'classnames';
import css from './styles.module.less';

export interface ImageProProps {
  src: string | undefined | null;
  className?: string;
  alt?: string;
  imgClassName?: string;
  imgStyle?: React.CSSProperties;
  size?: number | string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  noSpin?: boolean;

  isSkeleton?: boolean;
}

export const SimpleImagePro = (props: ImageProProps) => {
  const { borderRadius, noSpin, isSkeleton = false } = props;
  const [loaded, setLoaded] = useState(noSpin === true);
  let w: number | string = 0;
  let h: number | string = 0;
  if (props.size) {
    // size 存在，说明是正方形，让宽高等于 size
    w = props.size;
    h = props.size;
  } else {
    // 如果 size 不存在，说明长方形，尝试读取宽高
    w = props.width ? props.width : 'auto';
    h = props.height ? props.height : 'auto';
  }

  return (
    <div
      style={{ width: w, height: h, borderRadius: borderRadius }}
      className={classnames(
        css.imageProContainer,
        css.backgroundAnimation,
        loaded ? css.loaded : css.unload,
        props?.className
      )}
    >{
        !isSkeleton && <img
          src={props.src ? props.src : ''}
          style={{
            width: w,
            height: h,
            borderRadius: borderRadius,
            ...props.imgStyle,
          }}
          className={classnames(css.imagePro, props.imgClassName)}
          draggable={false}
          alt={props?.alt || 'image'}
          onLoad={() => setLoaded(true)}
        />}

    </div>
  );
};
