import i18n from '@/locales/init';
import { useAppSelector } from '@/stores';
import style from './index.module.less';

const hds:{[property: string]: any;} = {
  'title': i18n.t('题目'),
  'grammar': i18n.t('语法'),
  'type': i18n.t('题型'),
  'done_num': i18n.t('做过'),
  'result': i18n.t('最近批改'),
  'record_status': i18n.t('状态'),
  'record_time': i18n.t('时间'),
};
export const TrainingListHead = ()=>{
  const state = useAppSelector(store => store.basicTraingList);
  const {fields, selected} = state;
  let fs = fields;
  if (!selected.find(v=> v.key ==='grammar')) {
    fs = fields.filter(v=> v!=='grammar');
  }

  return <div className={style.wrapper} style={{
    gridTemplateColumns: `repeat(${fs.length + 1}, 1fr)`
  }}>
    {
      fs.map((field, index)=><div key={index}>{hds[field] || ''}</div>)
    }
    <div>{i18n.t('')}</div>
  </div>;
};