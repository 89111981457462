export default `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.537 18.0293V5.97073L12.0005 0L1.46387 5.97073V17.9707L12.0005 24L22.537 18.0293Z" fill="#F9E092"/>
<path d="M21 17.0283V6.97952L12 2.00391L3 6.97952V16.9795L12 22.0039L21 17.0283Z" fill="#04291F"/>
<g clip-path="url(#clip0_1348_5155)">
<path d="M11.9788 17.6521C14.8338 17.6521 17.1481 16.8573 17.1481 15.8768C17.1481 14.8964 14.8338 14.1016 11.9788 14.1016C9.12393 14.1016 6.80957 14.8964 6.80957 15.8768C6.80957 16.8573 9.12393 17.6521 11.9788 17.6521Z" fill="#F5C164"/>
<g filter="url(#filter0_i_1348_5155)">
<path d="M11.9788 17.6521C14.8338 17.6521 17.1481 16.8573 17.1481 15.8768C17.1481 14.8964 14.8338 14.1016 11.9788 14.1016C9.12393 14.1016 6.80957 14.8964 6.80957 15.8768C6.80957 16.8573 9.12393 17.6521 11.9788 17.6521Z" fill="#F5C164"/>
</g>
<path d="M6.11792 10.2344L15.7978 15.8437C15.9841 15.9517 16.0506 16.1947 15.9442 16.3837C15.8777 16.5052 15.7446 16.5862 15.6049 16.5862H7.5882C7.19568 16.5862 6.86304 16.2892 6.80982 15.891L6.11792 10.2344Z" fill="#F5C164"/>
<path d="M17.8541 10.2344L17.1555 15.891C17.1089 16.2892 16.7763 16.5862 16.3771 16.5862H8.37372C8.16083 16.5862 7.9812 16.4107 7.9812 16.188C7.9812 16.0462 8.05438 15.9112 8.18079 15.8437L17.8541 10.2344Z" fill="#F5C164"/>
<path d="M17.0764 9.83167C17.0764 10.2704 17.429 10.6282 17.8615 10.6282C18.2939 10.6282 18.6465 10.2704 18.6465 9.83167C18.6465 9.39291 18.2939 9.03516 17.8615 9.03516C17.4224 9.03516 17.0764 9.39291 17.0764 9.83167Z" fill="white"/>
<path d="M5.3335 9.83167C5.3335 10.2704 5.6861 10.6282 6.11853 10.6282C6.55097 10.6282 6.90357 10.2704 6.90357 9.83167C6.90357 9.39291 6.55097 9.03516 6.11853 9.03516C5.6861 9.03516 5.3335 9.39291 5.3335 9.83167Z" fill="url(#paint0_linear_1348_5155)"/>
<g opacity="0.752059" filter="url(#filter1_f_1348_5155)">
<path d="M7.02113 15.9531C7.03446 15.9566 7.04779 15.9601 7.06112 15.9671C7.59429 16.1936 9.20045 16.3575 11.0999 16.3575C10.9504 14.7464 10.7493 13.6101 10.4966 12.9485C10.3963 12.686 10.0679 12.4363 9.5113 12.1992L6.78787 15.3884C6.55461 15.5766 6.65458 15.8311 7.02113 15.9531Z" fill="#DC9F2B"/>
</g>
<path d="M11.9855 8.19531V16.7882H11.9788C10.0296 16.7882 8.39295 16.4574 7.90064 15.9984C7.6944 15.8567 7.55469 15.6137 7.55469 15.3437C7.55469 15.1884 7.60126 15.0399 7.68109 14.9117L11.9855 8.19531Z" fill="url(#paint1_linear_1348_5155)"/>
<g filter="url(#filter2_f_1348_5155)">
<path d="M16.9515 15.9531C16.9382 15.9566 16.9249 15.9601 16.9115 15.9671C16.3784 16.1936 14.7722 16.3575 12.8728 16.3575C13.0223 14.7464 13.2234 13.6101 13.4761 12.9485C13.5764 12.686 13.9048 12.4363 14.4614 12.1992L17.1848 15.3884C17.418 15.5766 17.3181 15.8311 16.9515 15.9531Z" fill="#DC9F2B"/>
</g>
<path d="M16.0571 16.0091C16.0438 16.0158 16.0305 16.0226 16.0172 16.0361C15.485 16.4748 13.8817 16.7921 11.9856 16.7921V8.19922L16.29 14.9156C16.5228 15.2801 16.4231 15.7728 16.0571 16.0091Z" fill="#F9CF7E"/>
<g filter="url(#filter3_i_1348_5155)">
<path d="M16.0571 16.0091C16.0438 16.0158 16.0305 16.0226 16.0172 16.0361C15.485 16.4748 13.8817 16.7921 11.9856 16.7921V8.19922L16.29 14.9156C16.5228 15.2801 16.4231 15.7728 16.0571 16.0091Z" fill="#F9CF7E"/>
</g>
<path d="M11.2014 7.80432C11.2014 8.24308 11.554 8.60084 11.9865 8.60084C12.4189 8.60084 12.7715 8.24308 12.7715 7.80432C12.7715 7.36557 12.4189 7.00781 11.9865 7.00781C11.554 7.00781 11.2014 7.36557 11.2014 7.80432Z" fill="white"/>
</g>
<defs>
<filter id="filter0_i_1348_5155" x="6.80957" y="14.1016" width="10.3386" height="3.99453" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.443746"/>
<feGaussianBlur stdDeviation="0.665619"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.302512 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1348_5155"/>
</filter>
<filter id="filter1_f_1348_5155" x="6.21861" y="11.7555" width="5.32499" height="5.04765" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.221873" result="effect1_foregroundBlur_1348_5155"/>
</filter>
<filter id="filter2_f_1348_5155" x="12.4291" y="11.7555" width="5.32499" height="5.04765" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.221873" result="effect1_foregroundBlur_1348_5155"/>
</filter>
<filter id="filter3_i_1348_5155" x="11.9856" y="8.19922" width="4.42969" height="9.0375" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="0.443746"/>
<feGaussianBlur stdDeviation="0.443746"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.313745 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1348_5155"/>
</filter>
<linearGradient id="paint0_linear_1348_5155" x1="5.5681" y1="9.03516" x2="6.66897" y2="10.6282" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#FFE19F"/>
</linearGradient>
<linearGradient id="paint1_linear_1348_5155" x1="277.935" y1="98.9828" x2="96.6804" y2="1435.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFF3D8"/>
<stop offset="1" stop-color="#FFE19F"/>
</linearGradient>
<clipPath id="clip0_1348_5155">
<rect width="13.3333" height="13.3333" fill="white" transform="translate(5.33301 5.33594)"/>
</clipPath>
</defs>
</svg>
`;