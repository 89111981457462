import { CSSProperties } from 'react';
import './index.less';
export const AIIcon = (props: { size?: number; style?: CSSProperties; className?: string; onClick?: () => void; }) => {
  const size = props.size || 28;
  const scale = size / 28;
  return <div className={props.className} style={{ ...props.style, width: size, height: size }} onClick={props?.onClick && props.onClick}>
    <div className='ai-robot' style={{ transform: `scale(${scale})` }}>
      <div className='ai-robot-head'>
        <div className='ai-robot-face'>
          <div className='ai-robot-eye eye1'></div>
          <div className='ai-robot-eye eye2'></div>
        </div>
      </div>
    </div>
  </div>;
};