import { Navigate, RouteProps } from 'react-router-dom';
import { withLazy } from '@/components/Lazy';
import { Redirect } from '@/modules/Redirect';
import { BasicTrainingHistory } from '@/modules/List/HistoryList/BasicTraining';
import { TrainingList } from '@/modules/List/TrainingList';
import { HomePage } from '../HomePage';

const LazyReadingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-reading_list" */'@/modules/List/ReadingList').then((res) => res.ReadingList));
const LazyListeningList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-listening_list" */'@/modules/List/ListeningList').then(res => res.ListeningList));
const LazyWritingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_list" */'@/modules/List/WritingList').then(res => res.App));
const LazyPracticeHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-practce_history_list" */'@/modules/List/HistoryList/PracticeHistory').then(res => res.PracticeHistory));
const LazyMockList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_history_list" */'@/pages/Simulated/App').then(res => res.App));
const LazySampleEssayHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay_history_list" */'@/modules/List/HistoryList/SampleEssayHistory').then(res => res.SampleEssayHistory));
const LazyWritingTask2EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_2_history_list" */'@/modules/List/HistoryList/WritingTask2EvaluationHistory').then(res => res.WritingTask2EvaluationHistory));
const LazyWritingTask1EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_1_history_list" */'@/modules/List/HistoryList/WritingTask1EvaluationHistory').then(res => res.WritingTask1EvaluationHistory));
const LazyMockTestHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_test_list" */'@/modules/List/HistoryList/MockTestHistory').then(res => res.MockTestHistory));
const LazyModelEssay = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay" */'@/modules/ModelEssay').then(res => res.ModelEssay));
const LazyWritingEvaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_2_evaluation" */'@/pages/Writing/sub-pages/Evaluation').then(res => res.WritingEvaluation));
const LazyWritingTask1Evaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_1_evaluation" */'@/pages/Writing/sub-pages/Essay/EssayEntry').then(res => res.EssayEntry));


export const routers: RouteProps[] = [
  { path: '/', Component: HomePage },
  // { path: '/', element: <Navigate to='/writing-list/1' replace /> },
  { path: '/writing-list', element: <Navigate to='/writing-list/1' replace /> },
  { path: '/writing-list/:module_id', Component: LazyWritingList },

  { path: '/model-essay', Component: LazyModelEssay },

  { path: '/big-essay/:recordId?', Component: LazyWritingEvaluation },
  { path: '/small-essay/:id?', Component: LazyWritingTask1Evaluation },
  { path: '/small-essay/:id/:recordId?', Component: LazyWritingTask1Evaluation },

  { path: '/writing-task-2/:recordId?', Component: LazyWritingEvaluation },
  { path: '/writing-task-1/:id?', Component: LazyWritingTask1Evaluation },
  { path: '/writing-task-1/:id/:recordId?', Component: LazyWritingTask1Evaluation },

  { path: '/reading-list', Component: LazyReadingList },
  { path: '/listening-list', Component: LazyListeningList },

  { path: '/history/practice/', Component: LazyPracticeHistory },
  { path: '/history/practice/:type', Component: LazyPracticeHistory },
  { path: '/history/mock_test', Component: LazyMockTestHistory },
  { path: '/history/sample_essay', Component: LazySampleEssayHistory },
  { path: '/history/evaluation', Component: LazyWritingTask2EvaluationHistory },
  { path: '/history/essay', Component: LazyWritingTask1EvaluationHistory },
  { path: '/history', Component: LazyPracticeHistory },
  { path: '/history/basic_training', Component: BasicTrainingHistory },

  { path: '/mock_list', Component: LazyMockList },


  { path: '/training-list/:type?', Component: TrainingList },

  { path: '/*', Component: Redirect },
];