/**
 * 解题思路相关
 */

import { post } from '@/utils/network/axios-helper';
import type { IGuideType } from '@/modules/Writing/ArgumentsAndEvidances/arguments-and-evidances.type';
import { IWritingThought } from '@/modules/Writing/AIRecord/plugins/WritingThoughts/writing-thought.types';

// 获取写作题目的论述角度
export const paperGuideMetaInfo = (id: number) => {
  return post<IGuideType>('/api/exercise/get-guide-info', { id });
};

export interface IGetArgumentsOfDirection_Request {
  /**
     * 角度id
     */
  angle_id: number;
  /**
   * 试卷id
   */
  paper_id: number;
  /**
   * 推荐记录id
   */
  record_id: number;
  /**
   * 练习类型：1非整篇 2整篇
   */
  type: number;
}

export interface IGetArgumentsOfDirection_Response extends IWritingThought {
  id: number;
  /**
   * 是否有更多数据：1是 2否
   */
  is_next: number;
}

// 根据论述角度获取论点论据
export const getArgumentsOfDirection = (params: IGetArgumentsOfDirection_Request) => {
  return post<IGetArgumentsOfDirection_Response>('/api/exercise/get-expand-recommend', params);
};
