import jsCookie from 'js-cookie';
import { authUrl, authCookieDomain } from '@/utils/env';
import jsBridgeApi from '@/utils/jsBirdge';
import { isNative } from '@/utils/platform';
import { logError, logInfo } from '@/utils/logger/arms-config';
import store from '@/stores';

const isTourist = ()=> store.getState().user.userPermission.isTourist;

/**
 * web 登录
 * !important 业务中尽量直接使用login
 */
export function loginWeb() {
  if (!isTourist()){
    jsCookie.remove('token', {
      domain: authCookieDomain,
    });
  }
  const loginUrl = `${authUrl}?redirect_uri=${btoa(window.location.href)}`;
  window.location.replace(loginUrl);
}

/**
 * native 登录
 * !important 业务中尽量直接使用login
 */
export function loginNative() {
  jsBridgeApi.openPage('/login');
}

// 登录(强制登录)
export function login() {
  console.log('[强制登录] cookie', document.cookie);
  logInfo({ type: 'login', token: !!jsCookie.get('token'), position: 'user_login' });
  if (!isTourist()){
    jsCookie.remove('token');
  }
  if (!isNative()) {
    setTimeout(() => {
      loginWeb();
    }, 200);
  } else {
    loginNative();
  }
}

// 登出
export function logout() {
  jsCookie.remove('token', {
    domain: authCookieDomain,
  });
  jsCookie.remove('token');
  if (!isNative()) {
    window.location.href = '/';
  } else {
    jsBridgeApi.close();
  }
}

// 检查是否有token，如果有，返回true，否则强制登录
// 游客token视同有临时用户，在此流程中不强制登录
export function checkHasToken(): boolean {
  const token = jsCookie.get('token');
  if (!token) {
    logError(new Error('接口需要token'));
    login();
    return false;
  } else {
    return true;
  }

}

