import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISearchItem, getContentSearchList } from '@/api/search';

interface ISearchContentState{
  content: string;
  page: number;
  page_size: number;
  list:ISearchItem[];
  total: number;
  type: number;
  reloadState: number;
}
const initialState:ISearchContentState = {
  content: '',
  page: 1,
  page_size: 20,
  list: [],
  total: 0,
  type: 1,
  reloadState: 0
};

export const getSearchList = createAsyncThunk('getSearchList/searchList', async (_, {
  rejectWithValue, getState,
}) => {
  try {
    const state = ((getState() as any).searchList) as ISearchContentState;
    const res = await getContentSearchList({ exam_type: state.type ,page: state.page, page_size: state.page_size, content: state.content });
    if (res.code !== 200) {
      rejectWithValue(null);
      return;
    }

    return res.data;
  } catch (e) {
    rejectWithValue(null);
  }
});
export const searchSlice = createSlice({
  name: 'createSlice',
  initialState,
  reducers: {
    setContent: (state, action:{payload: string;})=>{
      state.content = action.payload;
      state.page = 1;
    },
    getNextPage: (state)=>{
      state.page = state.page + 1;
    },
    /**
     * 类型 1听力 2阅读 2写作
     */
    setSearchType: (state, action:{payload:number;})=>{
      state.type = action.payload;
      state.content = '';
    },
    setReload: (state) => {
      state.reloadState = Math.random();
      state.page = 1;
    },
  },
  extraReducers: (builder)=>{
    builder
      .addCase(getSearchList.fulfilled,(state, action)=>{
        if (action.payload){
          state.total = action.payload.total;
          if (state.page === 1){
            state.list = action.payload.list;
          } else {
            state.list = state.list.concat(action.payload.list);
          }
          state.list.forEach((item)=>{console.log(item.type);});
        }
      });
  }

});

export const SearchListActions = searchSlice.actions;