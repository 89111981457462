import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu as AntdMenu } from 'antd';
import { cloneDeep } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { logInfo } from '@/utils/logger/arms-config';
import { appDispatch, useAppSelector } from '@/stores';
import { IBreadCrumb, setBreadCrumbs } from '@/stores/slices/userSlice';
import { SimpleIconFont } from '../Simple/SimpleIconFont';
import style from './index.module.less';

export interface MenuProps { }

export const Menu: React.FC<MenuProps> = () => {
  const menu = useAppSelector(state => state.user.menu);
  const flattenMenu = useAppSelector(state => state.user.flattenMenu);
  const module_id = useAppSelector(store => store.topicListSlice2.module_id);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]); // 默认展开项
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]); // 默认选中的项
  const location = useLocation();
  const navigate = useNavigate();

  // 将数据处理成渲染需要的格式
  const recursiveProcessMenu = (list: any[], layer = 0) => {
    list.forEach(item => {
      const hasChildren = item.children && item.children.length > 0;
      item.key = item.id;
      item.label = <span className={style[`menu-layer${layer}`]}>{item.name}</span>;
      if (item.icon) {
        item.icon = <SimpleIconFont type={item.icon} />;
      }
      if (hasChildren) {
        recursiveProcessMenu(item.children, layer + 1);
      }
    });
    return list;
  };

  // 根据 pathname 找到对应的菜单项目
  const findMenuItem = (location: any) => {
    const pathname = location.pathname as string;
    // const homeName = '/writing-list';

    // if (pathname === '/') {
    //   pathname = homeName;
    // }

    // 找到当前的
    const select = flattenMenu.find(d => {
      if (d.module_path) {
        return d.module_path !== '/' && (pathname.includes(d.module_path) || d.module_path === pathname);
      } else if (d.id === 1 && pathname === '/') {
        return true;
      } else {
        return null;
      }
    });

    if (select) {
      // 找到它所有的父亲
      let current = select;
      let doing = true;
      const openKeys: any[] = [];
      while (doing) {
        // eslint-disable-next-line no-loop-func
        const pItem = flattenMenu.find(d => d.id === current.pid);
        if (pItem) {
          openKeys.push(pItem);
          current = pItem;
        } else {
          doing = false;
        }
      }
      return {
        selectedKeys: [`${select.id}`],
        openKeys: openKeys.map(d => `${d.id}`)
      };
    } else {
      return {
        selectedKeys: [],
        openKeys: []
      };
    }
  };

  // 设置默认展开的菜单
  const setSelectedMenu = useCallback(() => {
    if (flattenMenu.length > 0) {
      const { selectedKeys, openKeys } = findMenuItem(location);
      appDispatch(setBreadCrumbs(getBreads([...openKeys, ...selectedKeys])));
      // setDefaultOpenKeys(openKeys);
      setDefaultSelectedKeys(selectedKeys);
    }
  }, [module_id, menu, flattenMenu, location]);

  useEffect(() => {
    setDefaultOpenKeys(menu.map(item => `${item.id}`));
  }, [menu]);

  const getBreads = (list: string[]) => {
    const result: IBreadCrumb[] = [];
    list.forEach(item => {
      const n = Number(item);
      const i = flattenMenu.find(v => v.id === n);
      if (i) {
        result.push({
          title: i.name,
          // href: i.module_path || undefined
        });
      }
    });
    return result;
  };


  // 选中了某一个菜单项
  const handleSelectedNavItem = useCallback((payload: any) => {
    setDefaultSelectedKeys([`${payload.key}`]);
    const next = flattenMenu.find(d => `${d.id}` === `${payload.key}`);
    const module_path = next.module_path;
    logInfo({ from: defaultSelectedKeys, to: module_path });
    navigate(module_path);
  }, [defaultSelectedKeys, flattenMenu]);

  const items = useMemo(() => {
    return recursiveProcessMenu(cloneDeep(menu));
  }, [menu]);
  useEffect(() => {
    setSelectedMenu();
  }, [setSelectedMenu]);

  return (
    <AntdMenu
      mode="inline"
      theme="light"
      inlineIndent={16}
      openKeys={defaultOpenKeys}
      selectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      items={items}
      expandIcon={() => <></>}
      onOpenChange={(payload) => {
        // setDefaultOpenKeys(payload);
      }}
      onSelect={(payload) => {
        handleSelectedNavItem(payload);
      }}
    />
  );
};