import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isChinese } from '@/utils/common';
import { ISaveSegmentedParams, IGetSegmentedParams, savePractice, submitPractice, submitSegmentedInfoApi, getSegmentedInfoApi } from '@/api/writing/practice';
import type { ReduxStateType } from '..';
import type { ITopicType } from './topicSlice';
export interface PracticeTips {
  content: string;
  title: string;
  question_key: string;
}
export interface CursorPositionType {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}
export interface PracticeEditType {
  char: string;
  lastIndex: number;
  wholeContent: string;
  cursorPosition: CursorPositionType;
}

export interface PracticeType {
  recordId: number;
  savingRecord: boolean;
  dirty: boolean;
  edit: PracticeEditType | null;
  enabledAIHelper: boolean;
  enabledAIWrite: boolean;
  basicAIWrite: any;
  basicAIWriteRequest: boolean;
  basicAItranslate2EN: string;
  basicAIHelperKeyAsking: string;
  basicAIHelperKeyGiveSentence: string;
  tempAIWrite: string;
  helperKeyHaveChange: any;
  helperKeyHaveChangeEnable: boolean;
  outline: any;
  practiceResponseAIStatus: boolean;
  mockTextTypeWritingMultiLineCount: number;
  mockTextTypeWritingMultiLineInfo: any;
  showPracticeResponseAI: boolean;
  cursorInfo: PracticeEditType | null;
  triggerAIRecord: number;
  practiceResponseAIAction: any;
  defaultInfo?: {
    outline: string;
    content: string;
  };
  segmentedInfo: any;
  segmentedInfoLoading: boolean;
}

const initialState: PracticeType = {
  recordId: 0,
  savingRecord: false,
  dirty: true,
  edit: {
    char: '',
    wholeContent: '',
    lastIndex: 0,
    cursorPosition: {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0
    }
  },
  // ai 写作组件的基本信息
  basicAIWrite: null,
  // ai 写作组件请求 ai 的状态
  basicAIWriteRequest: false,
  // ai 引导过程中将用户输入的中文翻译成英文
  basicAItranslate2EN: '',
  // ai 随便问问
  basicAIHelperKeyAsking: '',
  // ai 给我一些词或句子用户输入
  basicAIHelperKeyGiveSentence: '',
  // 是否显示 ai 帮助组件
  enabledAIHelper: false,
  // 是否显示 ai 写作组件
  enabledAIWrite: false,
  // ai 请求回来的结果
  tempAIWrite: '',
  // ai 换一换
  helperKeyHaveChange: null,
  // ai 缓一缓展示
  helperKeyHaveChangeEnable: false,
  // 草稿
  outline: undefined,
  // 打字机的状态 开始和结束
  practiceResponseAIStatus: false,
  // 统计打字机的次数
  mockTextTypeWritingMultiLineCount: 0,
  // 统计打字机次数并且要知道点了哪一个按钮
  mockTextTypeWritingMultiLineInfo: null,

  // 是否展示标题的打字机 ai
  showPracticeResponseAI: false,

  // 当前光标的位置
  cursorInfo: null,

  // ai 的结果是否被手动点击要插入正文
  triggerAIRecord: 0,

  // 控制我知道了的按钮
  practiceResponseAIAction: {},

  segmentedInfo: null,

  segmentedInfoLoading: false

};


const submitPracticeInfoAsync = async (wholeContent?: string, options?: any) => {
  const { getState } = options;
  const topic = (getState() as any).topic as ITopicType;
  const practice = (getState() as any).practice as PracticeType;
  if (!topic.info) {
    return;
  }
  const res = await submitPractice({
    exercise_id: topic.info.id,
    record_id: topic.info.record_id,
    content: wholeContent ? wholeContent : practice.edit?.wholeContent || '',
    outline: JSON.stringify(practice.outline),
  });

  if (res.code !== 200) {
    return;
  }
  return res.data;
};

const savePracticeInfoAsync = async (wholeContent?: string, options?: any) => {
  const { getState, dispatch } = options;
  const practice = (getState() as ReduxStateType).practice as PracticeType;
  const topic = (getState() as ReduxStateType).topic as ITopicType;

  if (!topic.info) {
    return;
  }
  if (!practice.dirty) {
    return;
  }

  dispatch({ type: 'practice/resetDirty' });

  let content = wholeContent ? wholeContent : practice.edit?.wholeContent || '';
  if (encodeURI(content) === '%0A') {
    content = '';
  }
  const res = await savePractice({
    exercise_id: topic.info.id,
    id: practice.recordId || topic.info.record_id,
    content: content,
    outline: JSON.stringify(practice.outline),
  });

  if (res.code !== 200) {
    return;
  }
  return res.data;
};

// 保存分段练习
export const saveSegmentedInfoAsync = async (data: ISaveSegmentedParams) => {
  const res = await submitSegmentedInfoApi(data);
  return res.data;
};

// 获取分段练习
export const getSegmentedInfoAsync = async (data: IGetSegmentedParams) => {
  const res = await getSegmentedInfoApi(data);
  return { ...res.data, type: data.type };
};

export const submitPracticeInfo = createAsyncThunk('practice/submitPracticeInfo', submitPracticeInfoAsync);
export const savePracticeInfo = createAsyncThunk('practice/savePracticeInfo', savePracticeInfoAsync);
export const saveSegmentedInfo = createAsyncThunk('practice/saveSegmentedInfo', saveSegmentedInfoAsync);
export const getSegmentedInfo = createAsyncThunk('practice/getSegmentedInfo', getSegmentedInfoAsync);

const practice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    resetDirty: (state) => {
      state.dirty = false;
    },
    setRecordOutline: (state, action) => {
      if (action.payload !== state.outline) {
        state.outline = action.payload;
        state.dirty = true;
      }
    },
    setPracticeContent: (state) => {
      state.dirty = true;
    },
    setEditInfoWidthSpecialCharChange: (state, action) => {
      const payload = action.payload;
      state.edit = payload;
      state.dirty = true;
    },
    setEditInfoWidthTranslateCN: (state, action) => {
      const payload = action.payload;
      if (payload) {
        const { char, lastIndex, wholeContent } = payload;
        if (char === '/') {
          let index = lastIndex - 1;
          let end = false;
          let result = '';
          while (!end) {
            if (index < 0) {
              end = true;
              break;
            }
            const current = wholeContent[index];
            // 判断是否中文
            const isCh = isChinese(current);
            // 如果是 / 直接跳过
            if (current === '/') {
              index--;
              continue;
            }
            // 如果拼接过了，并且本次不是中文那么就直接停止循环
            if (result !== '' && !isCh) {
              end = true;
              break;
            }
            // 如果是中文进行拼接
            if (isCh) {
              result += wholeContent[index];
            }
            index--;
          }
          const result_reverse = result.split('').reverse().join('');
          state.basicAItranslate2EN = result_reverse;
          return;
        }
        state.basicAItranslate2EN = '';
      } else {
        state.basicAItranslate2EN = '';
      }
    },
    setEnabledAIHelperStatus: (state, action) => {
      state.enabledAIHelper = action.payload;
    },
    setEnableAIWriteStatus: (state, action) => {
      state.enabledAIWrite = action.payload.enabledAIWrite;
      if (action.payload.basicAIWrite) state.basicAIWrite = action.payload.basicAIWrite;
      state.enabledAIHelper = false;
    },
    setBasicAIWriteRequest: (state, action) => {
      state.basicAIWriteRequest = action.payload;
    },
    combineTempContent: (state, action) => {
      state.tempAIWrite = state.tempAIWrite + action.payload;
    },
    setTempContent: (state, action) => {
      state.tempAIWrite = action.payload;
    },
    setBasicAIHelperKeyAsking: (state, action) => {
      state.basicAIHelperKeyAsking = action.payload;
    },
    setBasicAIHelperKeyGiveSentence: (state, action) => {
      state.basicAIHelperKeyGiveSentence = action.payload;
    },
    setHelperKeyHaveChange: (state, action) => {
      state.helperKeyHaveChange = JSON.parse(action.payload);
    },
    setHelperKeyHaveChangeEnable: (state, action) => {
      state.helperKeyHaveChangeEnable = action.payload;
    },
    setPracticeResponseAIStatus: (state, action) => {
      state.practiceResponseAIStatus = action.payload;
    },
    setMockTextTypeWritingMultiLineCount: (state, action) => {
      state.mockTextTypeWritingMultiLineCount = action.payload;
    },
    setMockTextTypeWritingMultiLineInfo: (state, action) => {
      state.mockTextTypeWritingMultiLineInfo = action.payload;
    },
    setShowPracticeResponseAI: (state, action) => {
      state.showPracticeResponseAI = action.payload;
    },
    setCursorInfo: (state, action) => {
      state.cursorInfo = action.payload;
    },
    setTriggerAIRecord: (state, action) => {
      state.triggerAIRecord = action.payload;
    },
    setPracticeResponseAIAction: (state, action) => {
      if (action.payload) {
        state.practiceResponseAIAction[action.payload.question_key] = action.payload;
      } else {
        state.practiceResponseAIAction = {};
      }
    },
    setInitPracticeDraft: (state, action: {
      payload: {
        recordId: number;
        content: string;
        outline: string;
      };
    }) => {
      if (!state.recordId) {
        // state.content = action.payload.content;
        state.recordId = action.payload.recordId;
        state.defaultInfo = {
          outline: action.payload.outline,
          content: action.payload.content,
        };
        state.outline = action.payload.outline;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePracticeInfo.pending, (state) => {
        state.savingRecord = true;
      })
      .addCase(savePracticeInfo.fulfilled, (state, action) => {
        state.savingRecord = false;
        if (!state.recordId && action.payload) {
          state.recordId = action.payload.id;
        }
      })
      .addCase(savePracticeInfo.rejected, (state) => {
        state.savingRecord = false;
        state.dirty = true;
      });

    builder
      .addCase(saveSegmentedInfo.pending, (state) => {
        state.segmentedInfoLoading = true;
      })
      .addCase(saveSegmentedInfo.fulfilled, (state, action) => {
        state.segmentedInfoLoading = false;
      })
      .addCase(getSegmentedInfo.rejected, (state) => {
        state.segmentedInfoLoading = false;
      });


    builder.addCase(getSegmentedInfo.fulfilled, (state, action) => {
      state.segmentedInfo = action.payload;
    });

  },
});

export const {
  setEditInfoWidthSpecialCharChange,
  setEnabledAIHelperStatus,
  setEnableAIWriteStatus,
  setBasicAIWriteRequest,
  combineTempContent,
  setTempContent,
  setEditInfoWidthTranslateCN,
  setBasicAIHelperKeyAsking,
  setBasicAIHelperKeyGiveSentence,
  setHelperKeyHaveChange,
  setHelperKeyHaveChangeEnable,
  setRecordOutline,
  setPracticeResponseAIStatus,
  setMockTextTypeWritingMultiLineCount,
  setMockTextTypeWritingMultiLineInfo,
  setShowPracticeResponseAI,
  setCursorInfo,
  setTriggerAIRecord,
  setPracticeContent,
  setPracticeResponseAIAction,
  setInitPracticeDraft,
} = practice.actions;
export default practice;
